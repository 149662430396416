import React, { useEffect, useCallback, useContext, useState, useMemo } from 'react';
import { ConfigContext } from '@trinity-incyte/context';
import { Button } from 'antd';
import { QSFieldListbox, Segment } from '@trinity-incyte/ui';

import './search-popup.module.scss';
import Utils from '@trinity-incyte/utils';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare var Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface SearchPopupProps {
	id: string;
	appId: string;
	parent: any;
	fieldExpression: string;
	handleClose: any;
	focus: boolean;
	element: HTMLElement;
	listboxId?: string;
}

declare const window: any;

export function SearchPopup({
	id,
	appId,
	parent,
	fieldExpression,
	handleClose,
	focus,
	element,
	listboxId = 'search-listbox',
}: SearchPopupProps) {
	const Config = useContext(ConfigContext);
	const screen = Utils.screen(Config);
	const [searchState, setSearchState] = useState('');

    function iOS() {
		return [
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod'
			].includes(navigator.platform)
			// iPad on iOS 13 detection
			||
			(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	}
	let isIos = iOS();

	const options:any = {
		'showTitles': true,
		'title': id,
	};

	const clearSelections = () => {
		const app = Mosaic.Qlik.app[appId];
		setSearchState('');
	
		app.selections.forEach(selection => { 
			if (fieldExpression.includes(selection.fieldName)) {
				app.field(selection.fieldName).clear();
			}
		});
	};


	  function debounce(callback, delay) {
		let timer;
		
		return function() {
		  clearTimeout(timer)
		  timer = setTimeout(() => {
			callback(searchState);
		  }, delay)
		}
	  }

	  const performSearch = (termToSearch) => {
			let fieldExpressionToUse:string = fieldExpression.replace('=', '').replace('[', '').replace(']', ''); // derm passes the field name with braces, but not as an array
			const app = Mosaic.Qlik.app[appId];
			app.field(fieldExpressionToUse).clear().then(() => {
			app.field(fieldExpressionToUse).toggleSelect(`*${termToSearch}*`, false);
	    })

		return;

	};

	  const setSearch = (e) => {
		let searchTerm = e.target.value;
		setSearchState(searchTerm);
		debounce((performSearch(searchTerm)), 5);
	  }


	// This search box only appears on ipad
	const SearchBoxIpad = () => {
		if (isIos) {
			return (
				<div className="searchBoxIpad" style={{width:'100%', backgroundColor:'#ffffff'}}>
					<div style={{ padding: '8px'}}>
						<input autoFocus onChange={setSearch} value={searchState} type="text" placeholder="Search..." id="searchbox" className="ant-input" style={{width:'80%', marginRight:'8px'}}></input>
						<button onClick={() => performSearch(searchState)} className="ant-btn ant-btn-primary">Search</button>
					</div>
				</div>
			)	
		} else {
			return <></>
		}
	}

	const clearButton = (
		<Button
			className='GridSearchClear'
			block
			style={{
				backgroundColor: '#1abc9c',
				borderColor: '#1abc9c',
			}}
			type="primary"
			onClick={clearSelections}
		>
			Clear
		</Button>
	);


	const closeButton = (
		<Button
			className='GridSearchClose'
			block
			style={{
				backgroundColor: 'black',
				borderColor: 'black',
			}}
			type="primary"
			onClick={handleClose}
		>
			Close
		</Button>
	);

	if (id?.toUpperCase()?.indexOf('DATE') !== -1) {
		options.qListObjectDef = {
			qDef: {
				"autoSort": false,
				"qSortCriterias": [{
					"qSortByState": 1,
					"qSortByAscii": 1,
					"qSortByNumeric": -1,
					"qSortByLoadOrder": 1,
					"qSortByFrequency": 0,
					"qSortByGreyness": 1
				}],
			}
		};
	}

	useEffect(() => {
		if ( parent ) {
			parent.app.visualization.create(
				'listbox',
				[fieldExpression],
				options,
			).then(( vis ) => {
				vis.show( listboxId );
				if ( focus ) {
					// This sub-ideal code opens the search input in the listbox
					setTimeout(() => {
						if ( !isIos ) {
							const lb = document.getElementById(listboxId);
							if ( !lb ) return;

							const lba = lb.querySelector("button[title='Search in listbox']") as HTMLButtonElement;
							if ( !lba ) return;

							lba.style.display = "none";

							lba.focus();
							lba.click();

							setTimeout(() => {
								const lbLb = lb.querySelector("input[placeholder='Search in listbox']") as HTMLInputElement;
								lbLb.focus();
							}, 750);

							return;

						}

					}, 750);
				}
			});
		}	
		
	}, []);
  
	const popupWidth = 30; // Becomes 30vw later
	let popupHeight;
	if (screen.height > 611) { // To account for different screen sizes
		popupHeight = 50;
	} else {
		popupHeight = 45;
	}
  
	const leftPx = useMemo(()=> {
		const columnHeaderElement:HTMLElement = element.offsetParent as HTMLElement;
		const headerRowElement:HTMLElement = columnHeaderElement.offsetParent.parentElement as HTMLElement;
		const tableContainerElement:HTMLElement = headerRowElement.offsetParent as HTMLElement; // Table container
		
		const tableContainerWidth = tableContainerElement.clientWidth;
		const popupWidthPX = ( popupWidth * window.innerWidth ) / 100; //This gets a percentage
		
		let left = columnHeaderElement.offsetLeft - headerRowElement.scrollLeft;
		if ((popupWidthPX < tableContainerWidth) && (left + popupWidthPX > tableContainerWidth)) left = tableContainerWidth - popupWidthPX;

		return left;
	}, []);

	return (
		<div 
			className={"gridSearch"}
			style={{
				position: 'absolute',
				left: `${leftPx}px`,
				top: `unset`,
				height: `${popupHeight}vh`,
				width: `${popupWidth}vw`,
				zIndex: 2000,
				padding: '0.25rem',
				marginTop: '2.25rem',
				border: '1px solid #eeeeee'
			}}
		>
			<Button.Group style={{ width: '100%' }}>
				{closeButton}
				{clearButton}
			</Button.Group>
			<SearchBoxIpad></SearchBoxIpad>
			
			<div id={listboxId} style={{ display:`${isIos?'block':'block'}`, height: `${popupHeight/2}vh` }}>{popupHeight}</div> 
		</div>
	);
}

export default SearchPopup;