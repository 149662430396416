import { Button, Popconfirm, Select } from 'antd';
import React, { useContext } from 'react';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { useBackend } from '@trinity-incyte/hooks';
import { ConfigContext } from '@trinity-incyte/context';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { dismissedAlerts as dismissedAlertsAtom } from '@trinity-incyte/recoil';
import { DismissCellProps } from '../dismiss-cell/dismiss-cell';
import {
	QlikUser as QlikUserAtom,
	QSAppMetadataFamily,
} from '@trinity-incyte/recoil';

/* eslint-disable-next-line */
export interface UnDismissCellProps {}

declare var window: any;
const BACKEND_CLASS_NAME = 'AlertsDismissals';
const Parse = useBackend();

export function UnDismissCell(props: DismissCellProps) {
	let profId;
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const QlikUser = useRecoilValue(QlikUserAtom);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const dismissedAlerts = useRecoilValue(dismissedAlertsAtom);
	const setDismissedAlerts = useSetRecoilState(dismissedAlertsAtom);

	function confirm(e) {
		const LBClass = Parse.Object.extend(BACKEND_CLASS_NAME);
		const query = new Parse.Query(LBClass);

		query.get(props.alertParseDbId, {
		}).then(
			(object) => {
				let unDimsissedDate = new Date();
				object.set('UndismissedDate', unDimsissedDate);
				object.save();
				let modifiedDismissedAlerts = dismissedAlerts.filter(x => x.AlertParseDbId!==props.alertParseDbId);
				setDismissedAlerts([
					...modifiedDismissedAlerts
				]);	
				window.appInsights?.trackEvent({name: `Button Clicked - Un-Dismissed alert - Home page alert un-dismissal - ${props.alertType}` });
			}
		);
	};


	const dismissDialog = () => {
			return (
			<>
			<div style={{minWidth:'300px', width:'300px'}}>Un-Dismiss?</div>
			</>
		)
	}

	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let content;
	if ( profId ) {
		content = (
				 <Popconfirm
								title={dismissDialog}
								onConfirm={confirm}
								okText="Yes"
								cancelText="No"
							>
							<Button
								className='button-cell alert-inner button-cell-home'
								data-action="self"
								size={props.size || "small"}
								style={{
									fontSize: props.fontSize || '.8em',
									...props.style,
								}}
							>
								Un-Dismiss
								<PlusCircleOutlined/>
						</Button>
					</Popconfirm>
		);
	} else {
		content = ( <></> );
	}

	return content;
}

export default UnDismissCell;
