import { Col, Row } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Menu } from 'antd';
import { rowFiltersState } from '@trinity-incyte/recoil';
import Utils from '@trinity-incyte/utils';
import useFilter from '../../Hooks/useFilter';
import DraggableMenu from '../../Menu/DraggableMenu';
import DraggablePanes from '../../Menu/DraggablePanes';
import Recent from './Recent';
import Upcoming from './Upcoming';
import { ConfigContext } from '@trinity-incyte/context';
import { CheckCircleFilled } from '@ant-design/icons';

const Notifications = ({initialPane, config}) => {
  const Config = useContext(ConfigContext);
	const [paneIndex, setPaneIndex] = useState(initialPane || 0);
	const fromTable = useRecoilValue<any>(rowFiltersState);
	const [selectedCompany, set_selectedCompany] = useState<boolean | string>(false);
	const [selectedTeam, set_selectedTeam] = useState<boolean | string>(false);
	const [selectedType, set_selectedType] = useState<boolean | string>(false);
	const history = useHistory();

	useEffect(() => {
		setPaneIndex(initialPane);
	}, [initialPane]);

	const screen = Utils.screen(Config);
	const selections = {
		selectedCompany,
		selectedTeam,
		selectedType,
	};

	const panes = [
		{
			menuItem: 'Recent',
			pane: {
				key: 'tab0',
				content: (
					<Row style={{height: '100%'}}>
						<Col flex="auto">
							<Recent config={config} selections={selections} />
						</Col>
					</Row>
				),
			},
		},
		{
			menuItem: 'Upcoming',
			pane: {
				key: 'tab1',
				content: (
					<Row style={{height: '100%'}}>
						<Col flex="auto">
							<Upcoming config={config} selections={selections} />
						</Col>
					</Row>
				),
			},
		},
    ];

    const filterEvents = useFilter({
			fromTable,
			set_selectedCompany,
			set_selectedTeam,
			set_selectedType
    });

	const content = (
		<DraggablePanes
			menu={(
				<DraggableMenu
					className="draggable-menu square-corners bottom-padding no-border"
					onChange={( activeIndex ) => {
						const tab = panes[activeIndex].menuItem;
						history.replace(`/Events/${tab}`);
					}}
					inverted
				>
					{
					panes.map(( val, ind ) => (
						<Menu.Item
							title={ val.menuItem}
							key={val.menuItem}
							icon={( ind === paneIndex ) ? <CheckCircleFilled /> : null}
							style={( ind === paneIndex )
								? { color: '#fff', backgroundColor:'#005cab', width:'50%', textAlign:'center'}
								: { color: '#fff', backgroundColor: 'black', width:'50%', textAlign:'center' }
							}
							className={( ind === paneIndex ) ? 'standout' : 'standdown'}
							onClick={() => {
								const { location } = history;
								if (location.pathname.indexOf('Events') > 0) {
									const tab = panes[ind].menuItem;
									history.replace(`/Events/${tab}`);
								} else {
									setPaneIndex(ind);
								}
							}}
						>{val.menuItem}</Menu.Item>
						))
					}
				</DraggableMenu>
			)}
			panes={panes}
			activeItem={paneIndex}
			style={{display: 'flex', flexDirection: 'column', height: '100%'}}
		>
        {filterEvents}
		</DraggablePanes>
	);

	return ( content );
};

export default Notifications;
