import React from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { Button, Row } from 'antd';
import useGlobal from '@trinity-incyte/store';
import { showFiltersState, closeSidebarsSelector, QlikUser as QlikUserAtom } from '@trinity-incyte/recoil';
import { ClearOutlined, FilterFilled } from '@ant-design/icons';

declare var window: any;

/* eslint-disable-next-line */
export interface FilterButtonsProps {}

export function FilterButtons(props: FilterButtonsProps | any) {
	const [, globalActions] = useGlobal();
	const { config } = props;
	const [showFilters, setShowFilters] = useRecoilState(showFiltersState);
	const closeSidebars = useSetRecoilState(closeSidebarsSelector);
  const QlikUser = useRecoilValue(QlikUserAtom);
	const onClickClear = () => {
		window.appInsights?.trackEvent({name: `Button Clicked - Main Nav - Clear Filters` });
		globalActions.Qlik.clearAppSelections( config, true );
		closeSidebars(null);
	};
	const content = (
		<>
			<div style={{display: 'flex', height: '100%'}}>
			{( !props.hideClear ) && (QlikUser.showSidebarFilters) && (
				<div
					style={{ minWidth:'70px', flexFlow:'1', flexDirection: 'column', display:'flex', height: '100%'}}
					className="btn-zoomer green with-icon"
					onClick={onClickClear}
				>
					<div style={{flex: 1}}></div>
					<ClearOutlined style={{fontSize: '0.9em', margin:'0px 2px 0px 0px', padding:'0'}} />
					<div style={{fontWeight:700, fontSize:'0.9em', textAlign:'center', width:'100%'}}>Clear</div>
					<div style={{flex: 1}}></div>
				</div>
			)}
			{( !props.hideFilters ) && (QlikUser.showSidebarFilters) && (
				<div 
					style={{ minWidth:'70px', flexFlow:'1', flexDirection: 'column', display:'flex', height: '100%'}}
					className="btn-zoomer orange with-icon"
					onClick={() => {
						window.appInsights?.trackEvent({name: `Button Clicked - Main Nav - Show Filters`});
						setShowFilters(!showFilters);
					}}
				>
					<div style={{flex: 1}}></div>
					<FilterFilled style={{fontSize: '0.9em', margin:'0px 2px 0px 0px', padding:'0'}} />
					<div style={{fontWeight:700, fontSize:'0.9em', textAlign:'center', width:'100%'}}>Filters</div>
					<div style={{flex: 1}}></div>
				</div>
			)}
			</div>
		</>
	);

	return ( content );
};

export default FilterButtons;
