import React from 'react';
import { Button, ButtonProps } from 'antd';
import { NavLink } from 'react-router-dom';

declare var window: any;

/* eslint-disable-next-line */
export interface TimelineCellProps {
    value?: any;
    column?: any;
		size?: ButtonProps['size'];
		fontSize?: any;
		style?: any;
		extraClass?: any;
}

export function TimelineCell(props: TimelineCellProps) {

	let profId;
	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let content;
	let extraClass = props.extraClass || "";
	let styleClasses = 'button-cell green-cell ' + extraClass;

	if ( profId ) {
		content = (
			<Button
				className={styleClasses}
				data-action="self"
				size={props.size || "small"}
				style={{
					fontSize: props.fontSize || '.8em',
					...props.style,
				}}
				onClick={(event) => {
					window.appInsights?.trackEvent({name: `Link Clicked - Open Timeline` });
				}}
			>
				<NavLink to={`/HCP360/IncyteActivity/Interactions/${profId}`}>Timeline</NavLink>
			</Button>
		);
	} else {
		content = ( <></> );
	}

	return content;
};

export default TimelineCell;
