import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { ConfigContext } from '@trinity-incyte/context';
import {
  activeTeam as activeTeamAtom,
  QlikUser as QlikUserAtom,
  QSAppMetadataFamily,
  UserTerritory as UserTerritoryAtom
} from '@trinity-incyte/recoil';
import { teamHasBrand } from '@trinity-incyte/utils';
import { Card, Col, Menu, Popover, Row, Statistic } from 'antd';
import React, { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';
import HCP360Cell from '../Addons/hcp360-cell/hcp360-cell';
import NavTerritory from '../Other/nav-territory/nav-territory';
import SimpleCarousel from '../Other/simple-carousel/simple-carousel';
import QSMashupObject from '../Qlik/qsmashup-object';
import { TopNav } from '../Groups/top-nav/top-nav';
import { CenterV } from '../boxes/CenterV';

declare var window: any;

const cardStyle = {
  background: 'transparent',
  border: '1px solid #F0F0F0',
  borderRadius: '0 1rem',
};

const cardHeadStyle = {
  color: 'aliceblue',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  border: 'unset',
};

const cardBodyStyle = {
  padding: '0 12px',
};

const ComparisonKPI = (props) => {
  const {
    style,
    title,
    value,
    valueStyle,
    headStyle,
    bodyStyle,
    prefix,
    suffix,
    onClick,
  } = props;

  return (
    <Card
      hoverable
      bordered
      onClick={onClick}
      size="small"
      style={{ ...cardStyle, ...style }}
      headStyle={{ ...cardHeadStyle, ...headStyle }}
      bodyStyle={{ ...cardBodyStyle, ...bodyStyle }}
      title={title}
      extra={
        <Statistic
          value={value}
          valueStyle={{ ...valueStyle, fontSize: '1.6rem' }}
          precision={1}
          prefix={prefix}
          suffix={suffix}
        />
      }
    />
  );
};

const getStyle = (value) => {
  const retVal = {
    color: 'aliceblue',
  };

  if (value === 0) {
    retVal.color = '#B3B3B3';
  } else if (value < 0) {
    retVal.color = '#e62231';
  } else {
    retVal.color = '#52b728';
  }

  return retVal;
};

const getPrefix = (value) => {
  let retVal;

  if (value === 0) {
    retVal = null;
  } else if (value < 0) {
    retVal = <ArrowDownOutlined />;
  } else {
    retVal = <ArrowUpOutlined />;
  }

  return retVal;
};

/* eslint-disable-next-line */
export interface PeriodOverPeriodProps { }

export function PeriodOverPeriod(props: PeriodOverPeriodProps) {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
  const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const { IDsTable: ids, FieldsTable: fields } = metadata;
	const activeTeam = useRecoilValue(activeTeamAtom);
	const UserTerritory = useRecoilValue(UserTerritoryAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [KPI1, set_KPI1] = useState(0);
	const [KPI2, set_KPI2] = useState(0);
	const [KPI3, set_KPI3] = useState(0);
	const gridItems = [];

  const carouselHeight = window.innerWidth < 1500 ? '70vh' : '58vh';
  const tableHeight = window.innerWidth < 1500 ? '67vh' : '55vh';

  const topTenPopover = (
    <SimpleCarousel
      carouselProps={{
        dots: { className: 'topten-dots' },
        style: { height: carouselHeight, width: '80vw' },
      }}
      slideTitles={[
        'Top 10 Prescribers',
        'Top 10 Gainers',
        'Top 10 Decliners',
        'Top 10 DS Accounts',
      ]}
      slides={[
        <QSMashupObject
          appId={appId}
          mashupId={ids.get('Mosaic_Main - Jakafi_table_Top 10 Prescribers')?.id}
          key='Mosaic_Main - Jakafi_table_Top 10 Prescribers'
          contentStyle={{ height: tableHeight, paddingBottom: '0' }}
          isTable
          showExports
          skipProfHeaders
          tableProps={{
            colProps: [{
              addon: HCP360Cell,
              profAddon: (props) => (null),
            }],
            colOverrides: [{
              columns: [0],
              hStyles: { width: "18rem" },
            }, {
              columns: [1, 2, 3, 4, 5],
              hStyles: { width: "4rem" },
            }, {
              columns: [6],
              hStyles: { width: "6rem" },
            }]
          }}
        />,
        <QSMashupObject
          appId={appId}
          mashupId={ids.get('Mosaic_Main - Jakafi_table_Top 10 Gainers - C13W')?.id}
          key='Mosaic_Main - Jakafi_table_Top 10 Gainers - C13W'
          contentStyle={{ height: tableHeight, paddingBottom: '0' }}
          isTable
          showExports
          skipProfHeaders
          tableProps={{
            colProps: [{
              addon: HCP360Cell,
              profAddon: (props) => (null),
            }],
            colOverrides: [{
              columns: [0],
              hStyles: { width: "18rem" },
            }, {
              columns: [1, 2, 3, 4, 5],
              hStyles: { width: "4rem" },
            }, {
              columns: [6],
              hStyles: { width: "6rem" },
            }]
          }}
        />,
        <QSMashupObject
          appId={appId}
          mashupId={ids.get('Mosaic_Main - Jakafi_table_Top 10 Decliners - C13W')?.id}
          key='Mosaic_Main - Jakafi_table_Top 10 Decliners - C13W'
          contentStyle={{ height: tableHeight, paddingBottom: '0' }}
          isTable
          showExports
          skipProfHeaders
          tableProps={{
            colProps: [{
              addon: HCP360Cell,
              profAddon: (props) => (null),
            }],
            colOverrides: [{
              columns: [0],
              hStyles: { width: "18rem" },
            }, {
              columns: [1, 2, 3, 4, 5],
              hStyles: { width: "4rem" },
            }, {
              columns: [6],
              hStyles: { width: "6rem" },
            }]
          }}
        />,
        <QSMashupObject
          appId={appId}
          mashupId={ids.get('Mosaic_Main - Jakafi_table_Top 10 Direct Sales Accounts')?.id}
          key='Mosaic_Main - Jakafi_table_Top 10 Direct Sales Accounts'
          contentStyle={{ height: tableHeight, paddingBottom: '0' }}
          isTable
          showExports
          skipProfHeaders
          tableProps={{
            colOverrides: [{
              columns: [0],
              hStyles: { width: "14rem" },
            }, {
              columns: [1, 2, 3, 4, 5],
              hStyles: { width: "4rem" },
            }]
          }}
        />
      ]}
    />
  );

  // Add the comparison KPIs & Top Ten
  if (teamHasBrand(Config.App.teamToBrand, activeTeam, "JAKAFI")) {
    gridItems.push(
      (
        <CenterV className='topNavItem' style={{flex: 1}} key='Top 10 KPIs C13WvP13W' keyToUse='Top 10 KPIs C13WvP13W inner'>
          <QSMashupObject
            appId={appId}
            mashupId={ids.get('Mosaic_Main - Jakafi_kpi_C13W v. P13W')?.id}
            key='Mosaic_Main - Jakafi_kpi_C13W v. P13W'
            noShow
            customFormatter={({ val1 }) => {
              set_KPI1(parseFloat(val1));
            }}
          />
          <Popover
            trigger="click"
            placement="bottomLeft"
            content={(
              <QSMashupObject
                appId={appId}
                mashupId={ids.get('Mosaic_Main - Jakafi_table_C13W v. P13W')?.id}
                key='Mosaic_Main - Jakafi_table_C13W v. P13W'
                isTable
                contentStyle={{ height: '12rem' }}
                tableProps={{
                  colOverrides: [{
                    columns: [0, 1],
                    hStyles: { width: "8rem" },
                  }, {
                    columns: [2, 3],
                    hStyles: { width: "6rem" },
                  }]
                }}
              />
            )}
          >
            <ComparisonKPI
              title="C13W v. P13W"
              value={KPI1}
              valueStyle={getStyle(KPI1)}
              prefix={getPrefix(KPI1)}
              onClick={() => window.appInsights?.trackEvent({name: 'Button Clicked - C13W v. P13W - Comparison KPI Clicked' })}
              suffix="%"
            />
          </Popover>
        </CenterV>
      ), (
        <CenterV className='topNavItem' style={{flex: 1}} key='Top 10 KPIs QTDvPQTD' keyToUse='Top 10 KPIs QTDvPQTD inner'>
          <QSMashupObject
            appId={appId}
            mashupId={ids.get('Mosaic_Main - Jakafi_kpi_QTD v. PQTD')?.id}
            noShow
            customFormatter={({ val1 }) => {
              set_KPI2(parseFloat(val1));
            }}
          />
          <Popover
            trigger="click"
            placement="bottom"
            content={(
              <QSMashupObject
                appId={appId}
                mashupId={ids.get('Mosaic_Main - Jakafi_table_QTD v. PQTD')?.id}
                key='Mosaic_Main - Jakafi_table_QTD v. PQTD'
                isTable
                contentStyle={{ height: '12rem' }}
                tableProps={{
                  colOverrides: [{
                    columns: [0, 1],
                    hStyles: { width: "8rem" },
                  }, {
                    columns: [2, 3],
                    hStyles: { width: "6rem" },
                  }]
                }}
              />
            )}
          >
            <ComparisonKPI
              title="QTD v. PQTD"
              value={KPI2}
              valueStyle={getStyle(KPI2)}
              prefix={getPrefix(KPI2)}
              onClick={() => window.appInsights?.trackEvent({name: 'Button Clicked - QTD v. PQTD - Comparison KPI Clicked' })}
              suffix="%"
            />
          </Popover>
        </CenterV>
      ), (
        <CenterV className='topNavItem' style={{flex: 1}} key='Top 10 KPIs YTDvPYTD' keyToUse='Top 10 KPIs YTDvPYTD inner'>
          <QSMashupObject
            appId={appId}
            mashupId={ids.get('Mosaic_Main - Jakafi_kpi_YTD v. PYTD')?.id}
            noShow
            customFormatter={({ val1 }) => {
              set_KPI3(parseFloat(val1));
            }}
          />
          <Popover
            trigger="click"
            placement="bottomRight"
            content={(
              <QSMashupObject
                appId={appId}
                mashupId={ids.get('Mosaic_Main - Jakafi_table_YTD v. PYTD')?.id}
                key='Mosaic_Main - Jakafi_table_YTD v. PYTD'
                isTable
                contentStyle={{ height: '12rem' }}
                tableProps={{
                  colOverrides: [{
                    columns: [0, 1],
                    hStyles: { width: "8rem" },
                  }, {
                    columns: [2, 3],
                    hStyles: { width: "6rem" },
                  }]
                }}
              />
            )}
          >
            <ComparisonKPI
              title="YTD v. PYTD"
              value={KPI3}
              valueStyle={getStyle(KPI3)}
              prefix={getPrefix(KPI3)}
              onClick={() => window.appInsights?.trackEvent({name: 'Button Clicked - YTD v. PYTD - Comparison KPI Clicked' })}
              suffix="%"
            />
          </Popover>
        </CenterV>
      ), (
        <CenterV className='topNavItem' style={{width: '110px'}} key='Top 10' keyToUse='Top 10 Inner'>
          <Popover
            trigger="click"
            placement="bottom"
            style={{ padding: '0' }}
            content={topTenPopover}
          >
            <ComparisonKPI
              title="TOP TEN"
              style={{ border: '2px solid #66cc99' }}
              headStyle={{ color: '#66cc99' }}
              value={' '}
              onClick={() => window.appInsights?.trackEvent({name: 'Button Clicked - Top Ten - Comparison KPI Clicked' })}
              prefix={null}
              suffix={null}
            />
          </Popover>
        </CenterV>
      )
    )
  }

	const content = (
		<TopNav
			config={config}
			showLastRX
			showFilters={(QlikUser?.features?.HOME?.showFilters === 'Y')}
		>
      {gridItems}
      <NavTerritory
        insideExistingNav
      />
		</TopNav>
	);

  return content;
};

export default PeriodOverPeriod;
