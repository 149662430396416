import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Carousel, Typography, Space } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

declare var window: any;

/* eslint-disable-next-line */
export interface SimpleCarouselProps {
  slides: any[],
  slideTitles?: string[],
  carouselProps?: any,
}

export function SimpleCarousel(props: SimpleCarouselProps) {
  const { slides, slideTitles, carouselProps } = props;
  const [currentSlide, set_currentSlide] = useState(0);
  const [titles, set_titles] = useState({
    prev: 'NULL',
    curr: 'NULL',
    next: 'NULL',
  });
  const ref = useRef(null);

  useEffect(() => {
    let currSlideTitle = slideTitles[currentSlide];
    let prevSlideTitle;
    let nextSlideTitle;
    if (currentSlide === 0) {
      prevSlideTitle = slideTitles[slideTitles.length - 1];
      nextSlideTitle = slideTitles[1];
    } else if (currentSlide === slideTitles.length - 1) {
      prevSlideTitle = slideTitles[currentSlide - 1];
      nextSlideTitle = slideTitles[0];
    } else if (currentSlide === slideTitles.length) {
      // This fixes a bug in carousel that gives the wrong index if you go prev from 0
      currSlideTitle = slideTitles[currentSlide - 1];
      prevSlideTitle = slideTitles[currentSlide - 2];
      nextSlideTitle = slideTitles[0];
    } else {
      prevSlideTitle = slideTitles[currentSlide - 1];
      nextSlideTitle = slideTitles[currentSlide + 1];
    }

    set_titles({
      prev: prevSlideTitle || 'Previous Slide',
      curr: currSlideTitle || 'Current Slide',
      next: nextSlideTitle || 'Next Slide',
    });
  }, [currentSlide]);

  return (
    <>
      <Row justify="space-between" align="bottom" gutter={[16, 16]}>
        <Col flex="inherit">
          <Button
            type="primary"
            size={'large'}
            // style={{ fontFamily: 'QlikView Sans' }}
            onClick={() => {
              if (ref.current) {
                ref.current.prev();
                window.appInsights?.trackEvent({name: `Button Clicked - ${titles.prev} - Carousel Previous Button Clicked` });
              }
            }}
          >
            <ArrowLeftOutlined /> {titles.prev}
          </Button>
        </Col>
        <Col flex="inherit">
          <Typography.Title style={{ marginBottom: '0' }} level={4}>{titles.curr.toUpperCase()}</Typography.Title>
        </Col>
        <Col flex="inherit">
          <Button
            type="primary"
            size={'large'}
            onClick={() => {
              if (ref.current) {
                ref.current.next();
                window.appInsights?.trackEvent({name: `Button Clicked - ${titles.next} - Carousel Next Button Clicked` })
              }
            }}
          >
            {titles.next} <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        <hr></hr>
      </Space>
      <Carousel
        beforeChange={(from, to) => {
          set_currentSlide(to);
        }}
        ref={ref}
        {...carouselProps}
      >
        {slides}
      </Carousel>
    </>
  );
}

export default SimpleCarousel;
