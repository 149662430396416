import { environment } from '../../../../../../apps/mosaic/src/environments/environment';
import { ConfigContext, HCP360Context } from '@trinity-incyte/context';

import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { TopNav, TopNavDropdown } from '../top-nav/top-nav';
import { useRecoilValue } from 'recoil';
import { QSAppMetadataFamily } from '@trinity-incyte/recoil';
import { CenterV } from '../../boxes/CenterV';

declare var window: any;
declare const Mosaic: MosaicGlobal;
const MAX_RECENT_ITEMS = 10


type RecentlyViewedItems = {
    name: string
    id: string
}

/* eslint-disable-next-line */

const NavButtonStyle = {
	borderColor: '#005CAB',
	fontSize: '1.2rem',
	fontWeight: 700,
    paddingLeft: '2rem',
    paddingRight: '2rem'
}

export interface NavGroupMosaicProps {
    urlSuffix?: string;
    showClear?: boolean;
    incyteActivities?: any[];
    publicActivities?: any[];
    size?: any;
    children?: ReactNode;
    hideConnections?: boolean
}

export function NavGroupMosaic(props: NavGroupMosaicProps) {
    const Config = useContext(ConfigContext);
    const hcp360Context = useContext(HCP360Context);
    const config = Config.Qlik.Prof360;
    const { appId, items } = config.ids;
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { FieldsTable: fields } = metadata;
    const app = Mosaic.Qlik.app[appId];
    const [IncyteActivity, set_IncyteActivity] = useState([]);
    const [PublicActivity, set_PublicActivity] = useState([]);
    const [Overview, set_Overview] = useState([]);
    const [recentlyViewedItems, setRecentlyViewedItems] = useState<Array<RecentlyViewedItems>>(JSON.parse(localStorage.getItem('HCP360RecentlyViewedItems')) || []);

    const history = useHistory();
    let { urlSuffix, incyteActivities, publicActivities, hideConnections = false } = props;
    if (!urlSuffix) urlSuffix = '/';
    let profIdForHistory = urlSuffix.replace('/', '');

    useEffect(() => {

        if (!hcp360Context?.profData?.profId) return;

        const profName = hcp360Context.profData?.bio?.Name;
        const profId = hcp360Context.profData.profId;

        // form the new entry object
        let newEntry: RecentlyViewedItems = { name: profName, id: profId };

        // initialize the new array
        let newRecentlyViewedItems = [...recentlyViewedItems];

        let recentItemsFoundIndex = newRecentlyViewedItems.find((recentItem) => recentItem.id == profId);

        // if it's already in there, remove it
        let itemInListIndex = newRecentlyViewedItems.indexOf(recentItemsFoundIndex);

        if (itemInListIndex != -1) {
            newRecentlyViewedItems.splice(itemInListIndex, 1);
            setRecentlyViewedItems(newRecentlyViewedItems);
        }

        // now add it at the beginning
        newRecentlyViewedItems = [newEntry, ...newRecentlyViewedItems];

        // get rid of the one at the end
        if (newRecentlyViewedItems.length > MAX_RECENT_ITEMS) {
            newRecentlyViewedItems.pop();
        }

        localStorage.setItem('HCP360RecentlyViewedItems', JSON.stringify(newRecentlyViewedItems));
        setRecentlyViewedItems(newRecentlyViewedItems);


    }, [hcp360Context.profData]);

    let dropDownItemsRecent = recentlyViewedItems.map((ri) => {
        return {
            text: `${ri.name} (${ri.id})`, to: `/HCP360/Overview/${ri.id}`, onClick: () => {
                const selectionField = fields.get(Config.Qlik.Prof360.ids.fields['PROF ID SELECTION'])?.id;
                const selectionValue = parseInt(ri.id, 10);
                const field = app.field( selectionField );
                field.clear().then(() => {
                    field.selectValues([selectionValue], false, true);
                })
            }, type: 'item'
        }
    });

    if (dropDownItemsRecent.length == 0) {
        dropDownItemsRecent = [{ text: 'No recent items', onClick: () => { }, to: '', type: 'other' }]
    }

    let dropDownItems = [
        { text: 'Search', to: '/HCP360/', type: 'item' },
        { text: null, to: '', onClick: null, type: 'divider' },
        { text: 'Recent HCPs:', to: '', onClick: null, type: 'header' },
        ...dropDownItemsRecent
    ];

    useEffect(() => {
        set_Overview([
            { text: 'Prof Overview', to: '/HCP360/Overview' + urlSuffix }
        ]);

        set_IncyteActivity(incyteActivities || [
            { text: 'Multi-Channel Engagement', to: '/HCP360/IncyteActivity/MCE' + urlSuffix, type: 'item' },
            { text: 'Speaker Programs', to: '/HCP360/IncyteActivity/SpeakerPrograms' + urlSuffix, type: 'item' },
            { text: 'Interactions', to: '/HCP360/IncyteActivity/Interactions' + urlSuffix, type: 'item' },
            { text: 'Clinical Trials', to: '/HCP360/IncyteActivity/ClinicalTrials' + urlSuffix, type: 'item' },
            { text: 'Ad Boards', to: '/HCP360/IncyteActivity/AdBoards' + urlSuffix, type: 'item' },
            { text: 'Alerts', to: '/HCP360/IncyteActivity/Alerts' + urlSuffix, type: 'item' },
            { text: 'Barriers', to: '/HCP360/IncyteActivity/Barriers' + urlSuffix, type: 'item' },
        ]);

        set_PublicActivity(publicActivities || [
            { text: 'Publications', to: '/HCP360/PublicActivity/Publications' + urlSuffix, type: 'item' },
            { text: 'Clinical Trials', to: '/HCP360/PublicActivity/ClinicalTrials' + urlSuffix, type: 'item' },
            { text: 'Presentations', to: '/HCP360/PublicActivity/Presentations' + urlSuffix, type: 'item' },
            { text: 'Grants', to: '/HCP360/PublicActivity/Grants' + urlSuffix, type: 'item' },
            { text: 'CMS Spend', to: '/HCP360/PublicActivity/CMS_Spend' + urlSuffix, type: 'item' },
            { text: 'Disclosures', to: '/HCP360/PublicActivity/Disclosures' + urlSuffix, type: 'item' },
        ]);
    }, [urlSuffix]);

    return (
        <TopNav
            {...props}
            dropdownText={(<span>HCP 360°</span>)}
            dropdownItems={dropDownItems}
            config={config}
        >
            {(urlSuffix !== '/') && (
                <>
                    <CenterV style={NavButtonStyle} className={`topNavItem interactable`} key="nav group hcp360 overview" keyToUse="nav group hcp360 overview inner"
                        onClick={() => {
                            history.push('/HCP360/Overview' + urlSuffix);
                            window.appInsights?.trackEvent({name: `Link Clicked - HCP360 Overview` });
                        }}
                    >
                        Overview
                    </CenterV>
                    <CenterV style={NavButtonStyle} className={`topNavItem interactable`} key="nav group hcp360 bio" keyToUse="nav group hcp360 bio inner"
                        onClick={() => {
                            history.push('/HCP360/Overview/Bio' + urlSuffix);
                            window.appInsights?.trackEvent({name: `Link Clicked - HCP360 Bio` })
                        }}
                    >
                        Bio
                    </CenterV>
                    {!!IncyteActivity.length &&
                        <TopNavDropdown
                            style={NavButtonStyle}
                            text={"Incyte Activity"}
                            items={IncyteActivity}
                            key="Incyte Activity"
                        >
                        </TopNavDropdown>
                    }
                    {!!PublicActivity.length &&
                        <TopNavDropdown
                            style={NavButtonStyle}
                            text={"Public Activity"}
                            items={PublicActivity}
                            key="Public Activity"
                        >
                        </TopNavDropdown>
                    }
                    {!hideConnections && <CenterV style={NavButtonStyle} className={`topNavItem interactable`} key={"HCP 360 Connections"} keyToUse={"HCP 360 Connections inner"}
                        onClick={() => {
                            history.push('/HCP360/Connections' + urlSuffix);
                            window.appInsights?.trackEvent({name: `Link Clicked - HCP360 Connections` })
                        }}
                    >
                        Connections
                    </CenterV>}
                    {environment.hasPreCallPlanning &&
                        <CenterV style={NavButtonStyle} className={`topNavItem interactable`} key={"HCP 360 Pre Call Planning"} keyToUse={"HCP 360 Pre Call Planning inner"}
                            onClick={() => {
                                history.push('/HCP360/PreCallPlanning' + urlSuffix);
                                window.appInsights?.trackEvent({name: `Link Clicked - HCP360 Pre Call Planning` })
                            }}
                        >
                            Pre Call
                        </CenterV>
                    }
                    </>
            )}
            {props.children}
        </TopNav>
    );
};

export default NavGroupMosaic;
