import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonProps } from 'antd';

declare var window: any;

/* eslint-disable-next-line */
export interface EventsCellProps {
  value?: any;
  column?: any;
	size?: ButtonProps['size'];
	fontSize?: any;
	style?: any;
}

export function EventsCell(props: EventsCellProps) {

	let profId;
	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let content;
	if ( profId ) {
		content = (
			<Button
				className='button-cell green-cell'
        data-action="self"
				size={props.size || "small"}
        style={{
					fontSize: props.fontSize || '.8em',
					...props.style,
				}}
				onClick={(event) => {
					window.appInsights?.trackEvent({name: `Link Clicked - Events Button` });
				}}
      >
        <NavLink to={`/Events/${profId}`}>Events</NavLink>
      </Button>
		);
	} else {
		content = ( <></> );
	}

	return content;
};

export default EventsCell;
