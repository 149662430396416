import { ConfigContext } from "@trinity-incyte/context"
import { QSAppMetadataFamily } from "@trinity-incyte/recoil"
import { QSMashupObject } from "@trinity-incyte/ui"
import React, { useContext } from "react"
import { useRecoilValue } from "recoil"

export const HCO360ReportsNiktimvoGraphs = () => {
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata
    
    const niktimvoTrxTotalSalesPct = `Mosaic_HCO 360_Niktimvo Trx Details_QoQ Total Sales Pct`
    
    return (
        <div style={{ padding: '4px' }}>
            <div style={{ display: 'flex', width: '100%', paddingLeft: '6px', marginTop: '12px' }}>
                <div style={{ width: '100%', height: '42vh' }}>
                    <QSMashupObject
                        showExports
                        exportAsPPT
                        exportAsXLS={false}
                        appId={appId}
                        objectKey={ids.get(niktimvoTrxTotalSalesPct)?.id}
                        mashupId={ids.get(niktimvoTrxTotalSalesPct)?.id}
                        compact
                        compactMargin
                    />
                </div>
            </div>
        </div>
    )
}