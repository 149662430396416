import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductNiktimvoInteractions = () => {
    const defaultLayout = {
        responsive: [
            {
                w: 24,
                h:12,
                i: 'HCO360 Niktimvo Speaker Programs'
            },
            {
                w: 24,
                h: 12,
                i: 'HCO360 Niktimvo Interactions'
            }]
        }

	return (
		<>
			<Grid
				title="Niktimvo Interactions"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductNiktimvoInteractions;
