import React, { Suspense, lazy, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { 
	DisclaimerGroup, 
	ModalGroup, 
	LocationKeeper, 
	SideNavGroup, 
	ProfWorkspaceGroup,
	Loader,
	SubPage,
	TrainerSelect
 } from '@trinity-incyte/ui';
import EventsCalendar from '../Pages/Events/Calendar';
import Events from '../Pages/Events/index';
import LandingPage from '../Pages/Index/index';
import ListBuilderIndex from '../Pages/ListBuilder/index';
import ListBuilderSegments from '../Pages/ListBuilder/Segments';
import { sideNavIsExpandedState, isMasqueradeActive as isMasqueradeActiveAtom,  QlikUser as QlikUserAtom } from '@trinity-incyte/recoil';
import { Layout } from 'antd';
import HCP360Home from '../Pages/HCP360/HCP360Home';
import HCO360Home from '../Pages/HCO360/HCO360Home';
import FieldLookupHome from '../Pages/FieldLookup/FieldLookup';
import ChatBot from '../Pages/ChatBot/ChatBot';
import FieldSalesHome from '../Pages/FieldSales/FieldSalesHome';
import { hasFeature } from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';

declare var window: any;

/*
 * REMEMBER: Switch goes first-match, so go most complex 1st to least complex
 */
const PAGE_ORDER = [
	{ flag: "home",			path: "/" },
	{ flag: "fieldSales",	path: "/FieldSales/" },
	{ flag: "events",		path: "/Events" },
	{ flag: "hcp360",		path: "/HCP360" },
	{ flag: "hco360",		path: "/HCO360" },
	{ flag: "listBuilder",	path: "/ListBuilder" },
	{ flag: "fieldLookup",		path: "/fieldLookup" },
	{ flag: "trainer",		path: "/Trainers" },
	{ flag: "chatBot",	path: "/chatbot" },
]

const getDefaultPage = (QlikUser) => {
	return PAGE_ORDER.find(page => QlikUser.pageVisibility[page.flag])
}

const AppRouterSwitch = ({ Config, QlikUser, isMasqueradeActive }) => (
	<Switch>
		{QlikUser.pageVisibility?.trainer && (
			<Route
				path="/Trainers" 
				render={(props) => {
					return (<TrainerSelect selectorUse={"trainers"} />);
				}}
			/>
		)}
		{(QlikUser?.isTrainer) && (!isMasqueradeActive) && (<Route><Redirect to="/Trainers" /></Route>)}
		{QlikUser.pageVisibility?.fieldSales && (<Route path="/FieldSales/" component={FieldSalesHome} /> )}
		{QlikUser.pageVisibility?.fieldSales && (<Route path="/OCNEReports/" component={FieldSalesHome} /> )}
		{QlikUser.pageVisibility?.events && (
			<Route path="/Events">
				<Switch>
					<Route path="/Events/Calendar" component={EventsCalendar} />
					<Route path="/Events/:param1" render={(props) => {
						const { param1 } = props.match.params;
						return (<Events activeTab={param1} />);
					}}
					/>
					<Route path="/Events" component={Events} />
				</Switch>
			</Route>
		)}
		{QlikUser.pageVisibility?.hco360 && hasFeature(Config.App.features, "HCO360") && (
			<Route path="/HCO360" component={HCO360Home} />
		)}
		{QlikUser.pageVisibility?.hcp360 && (
			<Route path="/HCP360">
				<Switch>
					<Route path="/HCP360/*/:profId" render={(props) => {
						const profId = props.match.params.profId;
						return (<HCP360Home profId={profId} />);
					}}
					/>

					<Route path="/HCP360/" component={HCP360Home} />
				</Switch>
			</Route>
		)}
		{QlikUser.pageVisibility?.listBuilder && (
			<Route path="/ListBuilder">
				<Switch>
					<Route path="/ListBuilder" component={ListBuilderIndex} />
					<Route path="/ListBuilder/Segments" component={ListBuilderSegments} />
					<Route path="/ListBuilder/Segments/:segmentName" render={(props) => {
						const { segmentName } = props.match.params;
						return (<ListBuilderSegments segmentName={segmentName} />);
					}}
					/>
				</Switch>
			</Route>
		)}
		{QlikUser.pageVisibility?.fieldLookup && (
			<Route path="/fieldLookup" component={FieldLookupHome} />
		)}
		{(hasFeature(Config.App.features, "chatbot")) && (QlikUser.pageVisibility?.chatBot) && (
		<Route
			path="/chatbot" 
            render={() => {
				return <ChatBot />
			}}
		/>
		)}
		{QlikUser.pageVisibility?.home && (<Route path="/"><LandingPage /></Route>)}
		<Route>
			<Redirect to={getDefaultPage(QlikUser).path} />
		</Route>
	</Switch>
);

// Called when my app has identified the user. Should probably put in a hook?
const Authenticated = (signInId) => {
    var validatedId = signInId.replace(/[,;=| ]+/g, "_");
    window.appInsights.setAuthenticatedUserContext(validatedId);
}

const AppRouter = (props) => {
	const Config = useContext(ConfigContext);
	const sideNavIsExpanded = useRecoilValue(sideNavIsExpandedState);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const isMasqueradeActive = useRecoilValue(isMasqueradeActiveAtom);

	let authUserToRecord = QlikUser.authenticatedUser;

	// Azure analytics user initialized
	Authenticated(authUserToRecord);

	return (
		<Layout>
			<SideNavGroup />
			<div className={`page transitionPoint3 ${sideNavIsExpanded ? 'navOpen' : 'navClosed'}`}>
				<Layout style={{ background:'#BFD9F1', height: '100%', width:'100%' }}>
					<Suspense
						fallback={(
							<>
								<Loader size="large"></Loader>
								<div style={{ height: '100%' }} />
							</>
						)}
					>
						<AppRouterSwitch Config={Config} QlikUser={QlikUser} isMasqueradeActive={isMasqueradeActive} />
					</Suspense>
				</Layout>
			</div>
			<DisclaimerGroup /> 
			<SubPage />
			<ModalGroup />
			<ProfWorkspaceGroup />
			<LocationKeeper />
		</Layout>
	);
};

export default AppRouter;
