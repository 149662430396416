import React, { useContext, useRef, useState } from 'react'
import { QSMashupObject } from '@trinity-incyte/ui';
import "./FieldLookup.scss"
import { useRecoilValue } from 'recoil'
import { ConfigContext } from '@trinity-incyte/context'
import { QSAppMetadataFamily } from '@trinity-incyte/recoil'
import { MosaicGlobal, QlikAppInstance } from '@trinity-incyte/api-interfaces'
import { Input, Spin, Typography } from 'antd'
import { PhoneFilled, MailOutlined, SearchOutlined } from '@ant-design/icons';

declare const Mosaic: MosaicGlobal

export const FieldLookupHome = (props) => {
    const Config = useContext(ConfigContext)
    const [ searchResults, setSearchResults ] = useState<Map<string, any>>(null)
    const [ searchString, setSearchString ] = useState<string>(null)
    const searchTaskTimeout = useRef<NodeJS.Timeout>()
    const config = Config.Qlik.FieldSales
    const { appId } = config.ids
    const app = Mosaic.Qlik.app[appId]
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids, FieldsTable: fields } = metadata
	const searchDelay = 500

    const copyToClipboard = (obj) => {
        let text = obj.target.innerHTML; 
        if (navigator.clipboard) {
          navigator.clipboard.writeText(text)
          .then(() => {
            alert('Text copied to clipboard!');
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
        }
      };

    const FieldLookupSearchResultItem = ({groupData}) => {
        const FIELD_FORCE_COLUMN_INDEX = 0
        const CITY_STATE_INDEX = 1
        const NAME_INDEX = 2
        const EMAIL_INDEX = 3
        const PHONE_INDEX = 4
        const MANAGER_NAME_INDEX = 5
        const MANAGER_EMAIL_INDEX = 6
        const MANAGER_PHONE_INDEX = 7

        const CONTACT_ICON_STYLE = {
            color: '#BEBF8A'
        }

        return (
            <>
                {groupData.map((entry) => (
                        <div className="search-result-details" key={`search-result-details_${groupData[0][NAME_INDEX].qText}_${entry[FIELD_FORCE_COLUMN_INDEX].qText}`}>

                            <div key={'group_INDEX'} style={{margin:'0.5em 0 0 0'}} className='search-result-header'>
                                <span style={{fontWeight:'bold'}}>{`${entry[FIELD_FORCE_COLUMN_INDEX].qText}`}</span> 
                                - {`${entry[CITY_STATE_INDEX].qText}`}
                            </div>

                            <div className={"contactBlockName"} key={'NAME_INDEX'}>
                                {`${entry[NAME_INDEX].qText}`}
                            </div>
                            <div className={"contactBlock"} key={'contactBlock_rep'}>
                                <div key={'EMAIL_INDEX'}>
                                    <MailOutlined style={CONTACT_ICON_STYLE} className="contactIcon" />
                                    <span className={`copyText`} onClick={copyToClipboard}>{`${entry[EMAIL_INDEX].qText}`}</span>
                                </div>
                                <div key={'PHONE_INDEX'}>
                                    <PhoneFilled  style={CONTACT_ICON_STYLE} className="contactIcon" />
                                    <span className={`copyText`} onClick={copyToClipboard}>{`${entry[PHONE_INDEX].qText}`}</span>
                                </div>
                            </div>

                            <div className={"contactBlockName"} key={'MANAGER_NAME_INDEX'}>
                                Manager: {`${entry[MANAGER_NAME_INDEX].qText}`}
                            </div>
                            <div className={"contactBlock"} key={'contactBlock_manager'}>
                                <div key={'MANAGER_EMAIL_INDEX'}>
                                    <MailOutlined style={CONTACT_ICON_STYLE} className="contactIcon" />
                                    <span className={`copyText`} onClick={copyToClipboard}>{`${entry[MANAGER_EMAIL_INDEX].qText}`}</span>
                                </div>
                                <div key={'MANAGER_PHONE_INDEX'}>
                                    <PhoneFilled style={CONTACT_ICON_STYLE} className="contactIcon" />
                                    <span className={`copyText`} onClick={copyToClipboard}>{`${entry[MANAGER_PHONE_INDEX].qText}`}</span>
                                </div>
                            </div>
                    </div>
                ))}
                </>
        )
    }

    const mapSearchResults = (searchResultsArray: Array<any>) => {
		const resultRowsByGroup = new Map<string, any>()
		searchResultsArray.forEach((searchResult) => {
			if (searchResult == null) return
			const resultRows = searchResult.qMatrix
			resultRows.map(row => {
				if (!resultRowsByGroup.has(row[1].qText)) resultRowsByGroup.set(row[1].qText, [])
				const unitResults = resultRowsByGroup.get(row[1].qText)
				unitResults.push(row)
			})
		})
		return resultRowsByGroup
	}

	const searchTask = async (text, callback, app) => {
		if (text == null || text == "") return null
		if (!callback) return null
		if (app) {
            const searchField = fields.get('Mosaic_Field Sales_Sales Team Search ZIP5')?.id;
            const searchTable = ids.get('Mosaic_Search_table_Sales Teams by Zip5')?.id;
            const object = await app.visualization.get(searchTable)
            const nameIdResults = await object.table.getFilteredByFieldSearch(app.field(searchField), text)
            callback(mapSearchResults([nameIdResults]))    
		} else {
			callback(false, text)
		}
	}

    const searchQlik = (text: string, callback: (results) => void, app: QlikAppInstance) => {
        if (searchTaskTimeout.current) {
            clearTimeout(searchTaskTimeout.current)
            searchTaskTimeout.current=null
        }
        if ((text == "") || (text.length<5)) {
            searchTaskTimeout.current = null
            return
        }
        searchTaskTimeout.current = setTimeout(searchTask, searchDelay, text, callback, app)
    }

    const onSearchStringUpdate = (newSearchString: string) => {
		setSearchResults(null)
		setSearchString(newSearchString)
		searchQlik(newSearchString, (results) => {
			searchTaskTimeout.current = null
			setSearchResults(results)
		}, app)
	}

    const resultsContent = Array<JSX.Element>()
    let resultsCount = 0

    if (searchTaskTimeout.current != null) {
        resultsContent.push(
            <div className='spinner-container' key={"spinner_container"}>
                <Spin size="large"/>
            </div>
        )
    } else if (searchResults != null) {
        if (searchResults.size > 0) {
            resultsCount = searchResults.size;
            searchResults.forEach((group, i) => {
                resultsContent.push(<FieldLookupSearchResultItem groupData={group} key={`searchResult_${i}`}/>)
            })
        } else 
        {
            resultsContent.push(<Typography.Title level={5} key={'no results'}>No Results</Typography.Title>)
        }
    }

    const CGAT_TABLE_ID = ids.get('Mosaic_Display_table_CGAT Sales Team')?.id;
    const PAYER_SALES_TEAM = ids.get('Mosaic_Display_table_Payer Sales Team')?.id;
    const resultsCountDisp = resultsCount>0?<span style={{marginLeft:'12px'}}>{resultsCount} Results</span>:<></>;

    return (
        <div style={{display:'flex', backgroundColor:'#fff', overflow:"auto"}} key={"field_info_search_page_container"}>
            <div key={'search_left'} className={'search-container'}>
                <div key={'search_header'}>
                    <div key={'search_instructions'} style={{fontWeight:"bold"}}>Search for:</div>
                    <div key={'search_instructions_zip'}>Zip Code</div>
                    <Input
                            onChange={({ target: { value } }) => onSearchStringUpdate(value)}
                            maxLength={5}
                            placeholder='Enter zip code (5 digit)'
                            suffix={<SearchOutlined />}
                            value={searchString}
                            style={{margin:'10px 0px 10px 0px', border: '1px solid #666', width:'20em'}}
                        />
                        {resultsCountDisp}
                </div>
                <div key={"search_results_container"} className='search-results-container'>
                    {resultsContent}
                </div>
            </div>

            <div key={'tables_container'} className={'tables-container'} style={{marginTop: '1.4em'}}>
                        <div key={'CGAT'}>
                            <div className="header-threesixty">{"CGAT"}</div>
                            <QSMashupObject
                                appId={appId}
                                objectKey={CGAT_TABLE_ID}
                                customClass="basicTable"
                                mashupId={CGAT_TABLE_ID}
                                isBasicTable
                                compact
                                compactMargin
                                {...props}
                            />
                        </div>
                        <div key={'PayerSalesTeam'} style={{marginTop: '1.4em'}}>
                            <div className="header-threesixty">{"Payer Sales Team"}</div>
                            <QSMashupObject
                                appId={appId}
                                objectKey={PAYER_SALES_TEAM}
                                customClass="basicTable"
                                isBasicTable
                                mashupId={PAYER_SALES_TEAM}
                                compact
                                compactMargin
                                {...props}
                            />
                        </div>
            </div>
        </div>
    )
}

export default FieldLookupHome