import { MosaicGlobal } from "@trinity-incyte/api-interfaces"
import { ConfigContext, HCO360Context } from "@trinity-incyte/context"
import { QSAppMetadataFamily } from "@trinity-incyte/recoil"
import { TabDef, TabGroup } from "@trinity-incyte/ui"
import React, { useContext, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { HCO360ReportsJakafiGraphs } from "./HCO360ReportsJakafiGraphs"
import { HCO360ReportsPemazyreGraphs } from "./HCO360ReportsPemazyreGraphs"
import { HCO360ReportsMonjuviGraphs } from "./HCO360ReportsMonjuviGraphs"
import { HCO360ReportsJakafiTables } from "./HCO360ReportsJakafiTables"
import { HCO360ReportsPemazyreTables } from "./HCO360ReportsPemazyreTables"
import { HCO360ReportsMonjuviTables } from "./HCO360ReportsMonjuviTables"
import { HCO360ReportsNiktimvoGraphs } from "./HCO360ReportsNiktimvoGraphs"
import { HCO360ReportsNiktimvoTables } from "./HCO360ReportsNiktimvoTables"

declare const Mosaic: MosaicGlobal;

type HCO360ReportsParams = {
    brand: string
    groupId: string
}

export const HCO360Reports = ({ brand, groupId }: HCO360ReportsParams) => {

    if (!brand || !groupId) return;

    const hco360Context = useContext(HCO360Context)
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata

    useEffect(() => {        
        hco360Context.setActiveView('Reports')
        hco360Context.setBrand(brand)
		hco360Context.applyGroupId(groupId)
		hco360Context.setShowGroup(true)
	}, [])

    const tabs = new Array<TabDef>()

    const clearFilters = () => {
        const hcp360App = Mosaic.Qlik.app[Config.Qlik.Prof360.ids.appId];
        if ( hcp360App ) {
            hcp360App.field( '%GP_ID' ).clearOther( true );
        }
    };

    switch (brand) {
        default:
        case 'Jakafi':
            tabs.push({name: "Account Sales Performance", comp: HCO360ReportsJakafiGraphs}, {name: "Child Accounts", comp: HCO360ReportsJakafiTables})
            break
        case 'Pemazyre':
            tabs.push({name: "Account Sales Performance", comp: HCO360ReportsPemazyreGraphs}, {name: "Child Accounts", comp: HCO360ReportsPemazyreTables})
            break
        case 'Monjuvi':
            tabs.push({name: "Account Sales Performance", comp: HCO360ReportsMonjuviGraphs}, {name: "Child Accounts", comp: HCO360ReportsMonjuviTables})
            break
        case 'Niktimvo':
            tabs.push({name: "Account Sales Performance", comp: HCO360ReportsNiktimvoGraphs}, {name: "Child Accounts", comp: HCO360ReportsNiktimvoTables})
            break
    }

    return (
        <TabGroup tabs={tabs} onTabChange={clearFilters} />
    )
}