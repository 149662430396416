import React from 'react';
import { Card, Col, Row } from 'antd';
import { useRecoilValue } from 'recoil';
import {
  activeTeam as activeTeamAtom,
  UserTerritory as UserTerritoryAtom
} from '@trinity-incyte/recoil';
import { CenterV } from '@trinity-incyte/ui';

const cardBodyStyle = {
	padding: '0 12px',
  minWidth:'180px',
};

export interface NavTerritoryProps {
  insideExistingNav?: boolean;
}

export function NavTerritory(props: NavTerritoryProps) {
  const activeTeam = useRecoilValue(activeTeamAtom);
	const userTerritory = useRecoilValue(UserTerritoryAtom);
    return (
      <>
        {props.insideExistingNav ? (
          <>
            <CenterV key="Team Selector" keyToUse="Team Selector inner" style={{flex: 1}}>
              <div style={{ ...cardBodyStyle, color: 'aliceblue', background: 'transparent' }}>
                <ul style={{ listStyle: 'none', marginBottom: '2px', fontSize: '1rem' }}>
                  {(activeTeam === 'HO1') && (
                    <li key={`HO1 Selector`}>{userTerritory.ho1}</li>
                  )}
                  {(activeTeam === 'HO2') && (
                    <li key={`HO2 Selector`}>{userTerritory.ho2}</li>
                  )}
                  {(activeTeam === 'HO3') && (
                    <li key={`HO3 Selector`}>{userTerritory.ho3}</li>
                  )}
                  {(activeTeam === 'OCNE') && (
                    <li key={`OCNE Selector`}>{userTerritory.ocne}</li>
                  )}
                </ul>
              </div>
            </CenterV>
          </>
        ) : (
          <CenterV key={"Territory Selector"} keyToUse={"Territory Selector Inner"} style={{flex: 1}}>
            <div key={"Territory Selector Container"} style={{ ...cardBodyStyle, color: 'aliceblue', background: 'transparent' }}>
              <ul key={"Territory Selector Control"} style={{ listStyle: 'none', marginBottom: '2px', fontSize: '1rem' }}>
                {(activeTeam === 'HO1') && (
                  <li key={"Territory HO1"}>{userTerritory.ho1}</li>
                )}
                {(activeTeam === 'HO2') && (
                  <li key={"Territory HO2"}>{userTerritory.ho2}</li>
                )}
                {(activeTeam === 'HO3') && (
                  <li key={"Territory HO3"}>{userTerritory.ho3}</li>
                )}
              </ul>
            </div>
            </CenterV>
        )}
      </>
    )
};

export default NavTerritory;
