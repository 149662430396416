import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { BackTop } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ResizeObserver from 'resize-observer-polyfill';

declare var window: any;

const IndicatorCircle = styled.circle`
    fill: white;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
`;

const ArrowStyles = css`
    position: absolute;
    color: #150101ab;
`;

const IndicatorArrowUp = styled(ArrowUpOutlined)`
    ${ArrowStyles}
`;

const IndicatorArrowDown = styled(ArrowDownOutlined)`
    ${ArrowStyles}
`;

const IndicatorBackTop = styled(BackTop)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    > div {
        opacity: 0.5;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-items: center;
    }
`;

export const useBackTop = () => {
    const [scroller, set_scroller] = useState<any>();
    const [resizer, set_resizer] = useState<any>();
    const onResizer = () => {
        if (resizer) {
            resizer(scroller);
        }
    };
    return [
        set_scroller,
        onResizer,
        <BackTopIndicator target={scroller} listenerFn={set_resizer} />,
    ] as [(_) => undefined, (_) => undefined, any];
};

/* eslint-disable-next-line */
export interface BackTopIndicatorProps {
    target: any;
    stroke?: any;
    color?: any;
    radius?: any;
    listenerFn: any;
}

export function BackTopIndicator({
    target,
    stroke = 4,
    color = '#4caf50',
    radius = 30,
    listenerFn = (_?) => undefined,
}: BackTopIndicatorProps) {
    const [normalizedRadius] = useState(radius - stroke * 2);
    const [circumference] = useState(normalizedRadius * 2 * Math.PI);
    const [offset, set_offset] = useState(circumference);
    const [visible, set_visible] = useState<any>(false);
    const [height, set_height] = useState();
    const memoizedCallback = useCallback(() => {
        const {
            scrollTop = 0,
            scrollHeight = 0,
            clientHeight = 0,
        } = (target as HTMLElement) || {
            scrollTop: 0,
            scrollHeight: 0,
            clientHeight: 0,
        };
        set_visible(clientHeight & scrollHeight && scrollHeight > clientHeight);
        set_offset(
            Math.abs(1 - (scrollTop / (scrollHeight - clientHeight) || 0)) *
                circumference
        );
    }, [target]);
    useEffect(() => {
        let isActive = true;
        listenerFn((_) => (target) => {
            if (target && isActive) {
                set_height(target.clientHeight);
            }
        });
        return () => {
            isActive = false;
        }
    }, []);

    useEffect(() => {
        if (height) {
            //Only height changes will now cause the logic to trigger
            memoizedCallback();
        }
    }, [height]);
    useEffect(() => {
        if (target) {
            target.addEventListener('scroll', memoizedCallback);

            const resizeObserver = new ResizeObserver(
                ([{ target: entryTarget }]: ResizeObserverEntry[]) => {
                    if (entryTarget === target) {
                        memoizedCallback();
                    }
                }
            );

            resizeObserver.observe(target);

            return () => {
                target.removeEventListener('scroll', memoizedCallback);
                resizeObserver.disconnect();
            };
        }
    }, [target]);
    return target && visible ? (
        <IndicatorBackTop
            target={() => target}
            visibilityHeight={0}
            visible={true}
            style={{
                width: normalizedRadius * 2 + stroke * 2,
                height: normalizedRadius * 2 + stroke * 2,
                right: normalizedRadius + stroke,
                bottom: (normalizedRadius + stroke) / 2,
            }}
            onClick={(e) => {
                window.appInsights?.trackEvent({name: `Button Clicked - ${window.location.pathname} - BackTop Indicator Button Clicked` })
                if (target?.scrollTop === 0) {
                    setTimeout(() => {
                        target.scrollTo({
                            top: target.scrollHeight,
                            behavior: 'smooth',
                        });
                    }, 500);
                }
            }}
        >
            <svg
                height={normalizedRadius * 2 + stroke * 2}
                width={normalizedRadius * 2 + stroke}
            >
                <IndicatorCircle
                    strokeDashoffset={offset}
                    strokeDasharray={`${circumference} ${circumference}`}
                    stroke={color}
                    strokeWidth={stroke}
                    r={normalizedRadius}
                    cx={normalizedRadius + stroke / 2}
                    cy={normalizedRadius + stroke}
                />
            </svg>
            {target.scrollTop ? (
                <IndicatorArrowUp
                    style={{
                        fontSize: normalizedRadius + stroke,
                        left: (normalizedRadius + stroke) / 2,
                    }}
                />
            ) : (
                <IndicatorArrowDown
                    style={{
                        fontSize: normalizedRadius + stroke,
                        left: (normalizedRadius + stroke) / 2,
                    }}
                />
            )}
        </IndicatorBackTop>
    ) : (
        <></>
    );
}

export default BackTopIndicator;
