import React from 'react';
import { RGLGrid as Grid } from '@trinity-incyte/ui';

const HCO360ProductNiktimvoSales = () => {
    const defaultLayout = {
        responsive: [{
            w: 15,
            items: [{
                items: [{
                    w: 8,
                    i: 'HCO360 Niktimvo Product Sales_Brand Units'
                },
                {
                    w: 16,
                    i: 'HCO360 Niktimvo Product Sales_Quintile Distribution of HCPs'
                }
            ]
            }, {
                items: [{
                    w: 8,
                    i: 'HCO360 Niktimvo Product Sales_Growth Rate'
                }, {
                    w: 16,
                    i: 'HCO360 Affiliations'
                }]
            }, {
                items: [{
                    w: 24,
                    i: 'HCO360 Niktomvo Key Individuals',
                }]
            }
        ]
        },
        {
            w: 9,
            items: [{
                w: 24,
                i: 'HCO360 Niktimvo Product Sales_R12M Volume'
            },
            ]
        }
    ]}

	return (
		<>
			<Grid
				title="Niktimvo Sales"
				className="mosaicOverviewLayout"
				layout={defaultLayout}
			/>
		</>
	);
};

export default HCO360ProductNiktimvoSales;
