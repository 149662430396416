import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Tabs
} from 'antd';

import './tabbed-container.module.scss';

declare var window: any;

const TabButton = ({name, active, onClick}) => {
	let content;
	if (active) {
		content = (
			<Button
				className='menu-item custom-tab standout'
				type="default" size="large"
        onClick={onClick}
			>
				{name}
			</Button>
		);
	} else {
		content = (
			<Button
				className='menu-item custom-tab standdown'
				type="default" size="large"
        onClick={onClick}
			>
				{name}
			</Button>
		)
	}

	return content;
};

/* eslint-disable-next-line */
export interface Pane {
	name: string;
  content: any;
}
/* eslint-disable-next-line */
export interface TabbedContainerProps {
	panes: Pane[];
	defaultTab?: string;
	onTabChange?: (activeKey: string) => void;
}

export function TabbedContainer({
  panes,
  defaultTab,
	onTabChange = () => {},
} : TabbedContainerProps) {
  const history = useHistory();
  defaultTab = history.location.pathname.includes('Details') ? panes[1].name : panes[0].name;
  const [activeTab, set_activeTab] = useState<string>(defaultTab);

	const content = (
		<Tabs
      defaultActiveKey={activeTab}
			centered
			onChange={(activeKey) => {
				set_activeTab(activeKey);
        onTabChange(activeKey);
			}}
			style={{
				height: '100%',
				background: 'white',
			}}
      renderTabBar={({onTabClick}) => {
        return (
          <div className="tab-bar">
            {panes.map((val) => {
              return (
                <TabButton
                  name={val.name}
									key={val.name}
                  active={(activeTab === val.name)}
                  onClick={(event) => {
						onTabClick(val.name, event);
						window.appInsights?.trackEvent({name: `Tab Button Clicked - ${val.name}` })
                  }}
                />
              )
            })}
          </div>
        )
      }}
		>
			{panes && panes.map((val, ind) => {

				return (
					<Tabs.TabPane
            tab={`tab ${val.name}`}
            active={(activeTab === val.name)}
            key={val.name}
            style={{
              height: '100%',
              backgroundColor: 'white', 
              borderRight: '1px solid #d9d9d9',
              borderBottom: '1px solid #D9D9D9',
              borderLeft: '1px solid #D9D9D9'
            }}
					>
						{(activeTab === val.name) && (val.content)}
					</Tabs.TabPane>
				)
			})}
		</Tabs>
	);

	return content;
}

export default TabbedContainer;