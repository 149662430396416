import Utils, { convertTeam, getPrimaryBrandForTeam, hasFeature, PathGenerators, toTitleCase } from '@trinity-incyte/utils';
import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	Divider,
	Image, 
	Popover,
	Layout,
} from 'antd';
import { MosaicLogoImg, MosaicDermLogoImg, imgDoctor, imgOrganization, imgUsers } from '@trinity-incyte/assets';
import {
    activeTeam as activeTeamAtom,
    IncyteTeam,
    QlikLastModified as QlikLastModifiedAtom,
    QlikUser as QlikUserAtom,
	isMasqueradeActive as isMasqueradeActiveAtom,
	masqueradeUserId as masqueradeUserIdAtom,
    sideNavSelector,
	openModalSelector
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import './side-nav-group.module.scss';
import SearchPopupCustom from '../../Qlik/qscustom-table/search-popup/search-popup-custom';
import { SearchOutlined, AndroidFilled, CalendarFilled, DoubleLeftOutlined, GlobalOutlined, HomeFilled, TableOutlined, ContactsOutlined, UserSwitchOutlined, BellFilled } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import TrainerSelect from '../../Other/trainer/trainer-select';
const { Sider } = Layout;

declare var window: any;
declare const Mosaic: MosaicGlobal;
let navIconStyle = { fontSize: '23px', padding: '7px' };

const FieldSalesMenuSegments = ({
	activeTeam,
	derm,
	sideNavIsExpanded
}) => {
	const QlikUser = useRecoilValue(QlikUserAtom);

	// track page views - possibly put into a hook so it only tracks once?
	window.appInsights?.trackPageView({name: location.pathname});

	const content = (<>
		{QlikUser.pageVisibility?.home && (
			<NavLink
				className='sideNavItem'
				isActive={(match, location) => { return location.pathname === '' || location.pathname === '/'; }}
				onClick={() => { window.appInsights?.trackEvent({name: 'Side Nav - Home Icon - Link Clicked' }) }}
				to='/'
			>
				<HomeFilled style={navIconStyle} />
				{sideNavIsExpanded && (
					<span className="sideNavGroupItemText" style={{ display: 'block' }}>Home</span>
				)}
			</NavLink>
		)}
		{QlikUser.pageVisibility?.fieldSales && (
			<NavLink
				className='sideNavItem'
				onClick={() => { window.appInsights?.trackEvent({name: 'Side Nav - Field Sales Icon - Link Clicked' }) }}
				to={PathGenerators.FieldSalesPathGenerator(activeTeam, `/${(derm && 'Derm') || (activeTeam === 'HO3' && 'Monjuvi' || 'Jakafi')}/`)}
			>
				<GlobalOutlined style={navIconStyle} />
				{sideNavIsExpanded && (
					<span className="sideNavGroupItemText" style={{ display: 'block' }}>{activeTeam === 'OCNE' ? 'OCNE Reports' : 'Field Sales'}</span>
				)}
			</NavLink>
		)}
		{QlikUser.pageVisibility?.events && (
			<NavLink
				className='sideNavItem'
				onClick={() => { window.appInsights?.trackEvent({ name: 'Side Nav - Events Icon - Link Clicked' }) }}
				to='/Events/'
			>
				<CalendarFilled style={navIconStyle} />
				{sideNavIsExpanded && (
					<span className="sideNavGroupItemText" style={{ display: 'block' }}>Events</span>
				)}
			</NavLink>
		)}
	</>)
	
	return content;
}

const TeamSelector = ({ QlikUser, expanded }) => {
	const Config = useContext(ConfigContext);
	const [activeTeam, set_activeTeam] = useRecoilState<IncyteTeam>(activeTeamAtom);
	const configFS = Config.Qlik.FieldSales;
	const config360 = Config.Qlik.Prof360;
	const { appId: appIdFS, fields: fieldsFS } = configFS.ids;
	const appFS = Mosaic.Qlik.app[appIdFS];
	const { appId: appId360 } = config360.ids;
	const app360 = Mosaic.Qlik.app[appId360];

	const clearSelections = () => {
		Config.Qlik.TeamFilteredApps.forEach((filteredAppName) => {
			let filteredAppConfigObj = Config.Qlik[filteredAppName];
			let filteredAppId = filteredAppConfigObj.ids.appId;
			let qlikAppObj = Mosaic.Qlik.app[filteredAppId];

			qlikAppObj.clearAll();
		});
	}

	const popoverContent = (
		<div style={{ minWidth: '5vw', display: 'flex', flexDirection: 'column' }}>
			{QlikUser.showTeamSelector.map((team) => 
				(<div
					className={`menuItem ${activeTeam === team?'active':''}`}
					key={team}
					style={{ textAlign: 'center' }}
					onClick={() => {
						window.appInsights?.trackEvent({name: `Side Nav - ${team} - Button Clicked` });
						clearSelections();
						set_activeTeam(team);
					}}
				>
					{team}
				</div>)
			)}
		</div>
	);

	const component = (
		<Popover trigger='click' content={popoverContent} placement='right'>
			<div 
				className='sideNavItem'
				onClick={() => { window.appInsights?.trackEvent({name: 'Side Nav - Team Layout - Clicked' })}}
				>
				<UserSwitchOutlined />
				{expanded && (
					<>
						<span style={{ fontSize: '0.9rem', color: 'white', cursor: 'pointer' }}>
							Team Layout
						</span>
						<span style={{ fontSize: '1rem', color: 'white', paddingTop: '5px' }}>
							{activeTeam}
						</span>
					</>
				)}
			</div>
		</Popover>
	);

	return component;
};

/* eslint-disable-next-line */
export interface SideNavGroupProps {
    simplified?: boolean;
    derm?: boolean;
}

export function SideNavGroup(props: SideNavGroupProps) {
	const Config = useContext(ConfigContext);
	const [sideNavIsExpanded, setSideNavIsExpanded] = useRecoilState(sideNavSelector);
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [masqueradePopup, set_masqueradePopup] = useState(false);
	const [isMasqueradeAtomActive, set_isMasqueradeAtomActive] = useRecoilState(isMasqueradeActiveAtom);
	const [masqueradeUserIdAtm, set_masqueradeUserIdAtm] = useRecoilState(masqueradeUserIdAtom);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const location = useLocation();
	
	const openModal = useSetRecoilState(openModalSelector);

	let {simplified, derm} = props;
	const configFS = Config.Qlik.FieldSales;
	const config360 = Config.Qlik.Prof360;
	const { appId: appIdFS, fields: fieldsFS } = configFS.ids;
	const appFS = Mosaic.Qlik.app[appIdFS];
	const { appId: appId360, fields: fields360 } = config360.ids;
	const app360 = Mosaic.Qlik.app[appId360]; 

	useEffect(() => {
		if (!appFS || !appFS.selections) return;
		const masqueradeFieldId = fieldsFS['Masquerade User Id'];
		let blnMasqueradeactive = appFS.selections.some(obj => (obj && obj.fieldName === masqueradeFieldId && obj.selectedCount > 0));
		set_isMasqueradeAtomActive(blnMasqueradeactive); // also used in trainer access in the router, setting here because situations arise where qlik session expires but atom persists		
		let masqueradeUserId = appFS.selections.find(obj => (obj && obj.fieldName === masqueradeFieldId && obj.selectedCount > 0));
		set_masqueradeUserIdAtm(masqueradeUserId?.qSelected);
	}, [appFS, QlikLastModified]);

	// These are the top-level categories for the app
	type PAGE_CATEGORIES = 'HCP360' | 'HCO360' | 'ListBuilder' | 'Events' | 'FieldSales' | 'Trainers' | 'Home';
	let pageCategory: PAGE_CATEGORIES;
	if (location.pathname.indexOf('/HCP360') >= 0) {
		pageCategory = 'HCP360';
	} else if (location.pathname.indexOf('/HCO360') >= 0) {
		pageCategory = 'HCO360';
	} else if (location.pathname.indexOf('/ListBuilder') >= 0) {
		pageCategory = 'ListBuilder';
	} else if (location.pathname === '/Trainers') {
		pageCategory = 'Trainers';
	} else if (location.pathname === '/Events/') {
		pageCategory = 'Events';
	} else if (location.pathname.indexOf('/FieldSales/') !== -1 || location.pathname.indexOf('/OCNEReports/') !== -1) {
		pageCategory = 'FieldSales';
	} else if (location.pathname === '/') {
		pageCategory = 'Home';
	} else {
		pageCategory = 'Home';
	}

	let content;
	if ( simplified ) {
		content = (
			<div id="sideNav" className={`compact ${(Config.App.name === 'MosaicDerm') ? 'purple' : 'blue'} navOpen centerChildren`}>
				{(Config.App.name === 'MosaicDerm') &&(<Image width="80px" height="auto" preview={false} src={MosaicDermLogoImg} alt="Mosaic Derm Logo"/>)}
				{(Config.App.name !== 'MosaicDerm') && (<Image width="80px" height="auto" preview={false} src={MosaicLogoImg} alt="Mosaic Logo"/>)}
				<br />
				<strong>{Config.App.name}</strong>
			</div>
		);
	} else {
		content = (
			<Sider className='noPrint' style={{backgroundColor:'green'}}>
				<div id="sideNav" className={`transitionPoint3 ${(derm) ? 'purple' : 'blue'} ${( sideNavIsExpanded ) ? 'navOpen' : 'navClosed'} noPrint`}>
					<NavLink
						className='sideNavItem'
						onClick={()=>{window.appInsights?.trackEvent({name: 'Side Nav - Mosaic Logo - Clicked'})}}
						isActive={()=>false /*Prevent highlighing*/}
						to='/'
					>
						{ (derm) && (<Image width='100%' height='auto' preview={false} src={MosaicDermLogoImg} alt='Mosaic Derm Logo' rootClassName='sideNavLogo' />)}
						{(!derm) && (<Image width='100%' height='auto' preview={false} src={MosaicLogoImg} alt='Mosaic Logo' rootClassName='sideNavLogo'/>)}
					</NavLink>
					<Divider style={{ margin: '11px 0px' }} type='horizontal'/>
					<FieldSalesMenuSegments
						activeTeam={activeTeam}
						derm={derm}
						sideNavIsExpanded={sideNavIsExpanded}
					/>
					{QlikUser.pageVisibility?.hcp360 && (
						<NavLink
							className='sideNavItem'
							onClick={() => { window.appInsights?.trackEvent({name: "Side Nav - HCP 360 - Link Clicked"}) }}
							to='/HCP360'
						>
							<Image
								rootClassName={'sideNavImage'}
								preview={false}
								height={'21px'}
								width={'21px'}
								// style={{minWidth:'21px', minHeight:'21px', margin:'1px'}}
								src={imgDoctor}
							/>
							{sideNavIsExpanded && (
								<span className="sideNavGroupItemText" style={{ display: 'block' }}>HCP 360°</span>
							)}
						</NavLink>
					)}
					{QlikUser.pageVisibility?.hco360 && hasFeature(Config.App.features, "HCO360") && (
						<NavLink
							className='sideNavItem'
							onClick={()=>{window.appInsights?.trackEvent({name: "Side Nav - HCO 360 Click"})}}
							to='/HCO360'
						>
							<Image
								rootClassName={'sideNavImage'}
								preview={false}
								height={'25px'}
								width={'27px'}
								src={imgOrganization}
							/>
							{ sideNavIsExpanded && (
								<span className="sideNavGroupItemText" style={{ display: 'block' }}>HCO 360°</span>
							)}
						</NavLink>
					)}
					{QlikUser.pageVisibility?.listBuilder && (
						<NavLink 
						className='sideNavItem'
						onClick={() => {window.appInsights?.trackEvent({ name: 'Side Nav - List Builder Icon - Clicked' })}}
						to='/ListBuilder'
					>
						<TableOutlined style={navIconStyle}/>
						{ sideNavIsExpanded && (
							<span className="sideNavGroupItemText" style={{ display: 'block' }}>List Builder</span>
						)}
					</NavLink>
					)}
					{QlikUser.pageVisibility?.fieldLookup && (
					<NavLink 
						className='sideNavItem'
						onClick={()=>{window.appInsights?.trackEvent({name : 'Side Nav - Field Lookup - Link Clicked'})}}
						to='/fieldLookup/'
					>
					<SearchOutlined style={navIconStyle}/>
					{ sideNavIsExpanded && (
						<span className="sideNavGroupItemText" style={{ display: 'block' }}>Field Lookup</span>
					)}
					</NavLink>
					)}
					{QlikUser.pageVisibility?.chatBot && hasFeature(Config.App.features, "chatbot") && Config.App.embedLinks.chatBot.find((obj) => obj.teams.includes(activeTeam)) && (
					<NavLink 
						className='sideNavItem'
						onClick={()=>{window.appInsights?.trackEvent({name : 'Side Nav - Mosaic AI - Link Clicked'})}}
						to='/chatbot/'
					>
					<AndroidFilled style={navIconStyle}/>
					{ sideNavIsExpanded && (
						<span className="sideNavGroupItemText" style={{ display: 'block' }}>Mosaic AI</span>
					)}
					</NavLink>
					)}
					{hasFeature(Config.App.features, "AlertsPage") && (
						<NavLink 
							className='sideNavItem'
							onClick={() => { window.appInsights?.trackEvent({ name: 'Side Nav - Alerts Icon - Link Clicked' })}}
							to='/Alerts'
						>
							<BellFilled style={navIconStyle}/>
							{ sideNavIsExpanded && (
								<span className="sideNavGroupItemText" style={{ display: 'block' }}>Alerts</span>
							)}
						</NavLink>
					)}
					{QlikUser.pageVisibility?.trainer && (
						<NavLink 
							className='sideNavItem'
							onClick={() => { window.appInsights?.trackEvent({ name: 'Side Nav - Trainer Home Icon - Link Clicked' })}}
							to='/Trainers'
						>
							<ContactsOutlined style={navIconStyle}/>
							{ sideNavIsExpanded && (
								<span className="sideNavGroupItemText" style={{ display: 'block' }}>Trainer</span>
							)}
						</NavLink>
					)}
					<div className='snap-bottom' style={{width: '100%'}}>
						{( QlikUser.showTeamSelector && (!QlikUser.isTrainer || location.pathname.indexOf('/Trainers') !== -1) ) && (
							<TeamSelector QlikUser={QlikUser} expanded={sideNavIsExpanded}/>
						)}
						{ QlikUser.showMasquerade && (
							<>
								<Popover
									content={(<><span>Build Date:</span><br />{Utils.moment(Config.App.buildDate).format('DD/MM/YYYY hh:mmA')}</>)}
									placement='top'
									trigger='click'
								>
									<Divider/>
								</Popover>
								<div
									className='sideNavItem'
									id="menuItemMasquerade"
									style={{ color: '#fff', backgroundColor: (isMasqueradeAtomActive===true) ? 'mediumseagreen' : null }}
									onClick={() => {
										window.appInsights?.trackEvent({ name: 'Side Nav - Toggle Masquerade - Clicked' });
										// This doesn't play well with handleClose of the popup
										// Clicking on the item while it's open will open it again
										// TODO: Fix this - low priority
										openModal({
											closable: true,
											content: <TrainerSelect showOkButton={true} hideGoButton={true} />
										})
									}}
								>
									<Image 
										preview={false}
										height={'0.7rem'}
										width={'1rem'}
										src={imgUsers} 
										rootClassName={'sideNavImageSmall'}
									/>
									{ sideNavIsExpanded && <span style={{ display: 'block' }}>Masquerade</span>}
								</div>
								{( masqueradePopup ) && (
									<SearchPopupCustom
										key={`masquerade_popup_${pageCategory}`} // This is intended to make sure it refreshes when the user switches page categories
										// fieldExpression={(pageCategory === 'HCP360' || pageCategory === 'ListBuilder') ? fields360['Masquerade User Id'] : fieldsFS['Masquerade User Id']}
										fieldExpression={fields360['Masquerade User Id']}
										appId={appIdFS}
										selectionAppIds={[appIdFS, appId360]}
										isDimension
										isMeasure={false}
										handleClose={() => set_masqueradePopup( false )}
										listboxId="listbox-masquerade"
										id="User Masquerade"
										invokeElementId="menuItemMasquerade"
										coordinates={document.getElementById('menuItemMasquerade').getBoundingClientRect()}
									/>
								)}
							</>
							)}
						<Divider type='horizontal' />
						<div
							className='sideNavItem'
							onClick={() => { setSideNavIsExpanded(!sideNavIsExpanded) }}
						>
							<DoubleLeftOutlined className="transitionPoint3" style={( sideNavIsExpanded ) ? { fontSize: '1rem' } : { transform: 'rotate(180deg)', fontSize: '1rem' }} />
						</div>
					</div>
				</div>
			</Sider>
		);
	}

    return ( content );
};

export default SideNavGroup;