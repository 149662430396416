import React, { useState, useRef } from 'react';
import {
	Button as AntButton,
	Divider,
	Space,
	Row,
	Col,
} from 'antd';
import {
	generate as generatePalette,
} from '@ant-design/colors';
import Utils from '@trinity-incyte/utils';
import { HCP360Cell, ListCard, ListCardLabel, ProfKPIGroup, RecentEventsTable } from '@trinity-incyte/ui';
import { DownCircleFilled, LeftCircleOutlined } from '@ant-design/icons';
import { HCP360State } from '@trinity-incyte/context';
import EventStyles from './Event.module.scss';

declare var window: any;

const Event = ( props ) => {
	const [showItems, set_showItems] = useState(false);
	const [showActions, set_showActions] = useState(false);
	const measureRef: any = useRef();


	const {
		labels,
		icon,
		date,
		prof,
		profId,
		brandName,
		companyName,
		teamName,
		employeeName,
		activity,
		count,
		style,
	} = props;

	const mosaicGreen		= generatePalette('#00cc22');
	const mosaicPurple 	= generatePalette('#8800cc');
	const mosaicTeal 		= generatePalette('#00aacc');
	const mosaicRed 		= generatePalette('#cc2200');
	const mosaicMaroon 	= generatePalette('#550080');
	const mosaicBlue 		= generatePalette('#2200cc');
	const brandBlue			= generatePalette('#005cab');
	const productColors = {
			'Monjuvi': '#256678',
			'Jakafi': '#6db73c',
			'Pemazyre': '#ed6551',
	};

	return (
		<ListCard color={( companyName === 'MorphoSys' ) ? 'red' : 'blue'}>
			<div style={{ position: "absolute", left:"-5px", top:"-2px"}}>
				<ListCardLabel component={
					<AntButton
						size="small"
						style={{
							backgroundColor: (companyName === 'MorphoSys') ? productColors['Monjuvi'] : productColors['Jakafi'],
							borderColor: (companyName === 'MorphoSys') ? productColors['Monjuvi'] : productColors['Jakafi'],
							maxHeight: "22px"
						}}
					>
						{`${companyName} ${teamName}`}
					</AntButton>}
				>
				</ListCardLabel>
			</div>
			<div>
				<div style={{color: '#595959', width:'100%'}} className="no-padding">
					<Row style={{width:"100%", paddingLeft:"1.5%", paddingBottom: "0.3%"}}>
						<Col style={{ maxWidth:"80%", paddingTop: '1.9rem'}} span={18}>
							<HCP360Cell value={profId} size="small" style={{ width: '6rem', position:"absolute", top:"-1px", right:"-1px"  }}/>
							<Row>
								<div style={{ fontSize: '1.4rem', float: 'left' }}>
									<strong>
										{Utils.moment(date).format('MM-DD-YYYY')}
										{'  |  '}
										{icon}
										{' '}
										{activity}
										{'  |  '}
										{prof || ''}
									</strong>
									<br />
									<i>{`${employeeName || ''}`}</i>
								</div>
							</Row>
						</Col>
						<Col style={{ textAlign:"left", paddingLeft: "3px" }} span={6}>
							<Space direction="vertical" size={4} style={{ width: '100%' }}>
								<Row style={{ height: '100%', width: '100%'}}>
									<AntButton
										type="default"
										block
										style={{ backgroundColor: brandBlue[0], paddingLeft: '2px', paddingRight: '2px' }}
										onClick={() => {
											window.appInsights?.trackEvent({name: `Button Clicked - Toggles Showing or Hiding Prof Card` })
											set_showActions(!showActions);
										}}
									>
												Prof Card
												{(showActions)
													? (<DownCircleFilled />)
													: (<LeftCircleOutlined />)}
									</AntButton>
								</Row>
								<Divider type="horizontal" style={{ margin: '0' }} />
								<Row style={{ height: '100%', width: '100%'}}>
									<AntButton
										type="default"
										block
										style={{ backgroundColor: brandBlue[0], paddingLeft: '2px', paddingRight: '2px' }}
										onClick={() => {
											window.appInsights?.trackEvent({name: `Button Clicked - Toggles Showing or Hiding Recent Events` });
											set_showItems(!showItems);
										}}
									>
											Recent Events
												{(showItems)
													? (<DownCircleFilled />)
													: (<LeftCircleOutlined />)}
									</AntButton>
								</Row>
							</Space>
						</Col>
					</Row>
				</div>
			</div>
			<div style={{ display: showActions || showItems ? '' : 'none', width: '100%', overflowY: 'auto', backgroundColor: '#d0d9e0' }}>
				<div ref={measureRef}>
					{showActions && (
						<HCP360State profId={props.profId}>
							<ProfKPIGroup
								profId={profId}
								horizontal
								basic
								layout="card"
							/>
						</HCP360State>
					)}
					{showItems && (<RecentEventsTable profId={profId} />)}
				</div>
			</div>
		</ListCard>
	);
};

export default Event;
