/**
 * Is imported by qscustom-table
 * Handles rendering and on-DOM events
 */
 import { Empty, Spin } from 'antd';
 import React, { useCallback, useMemo, useRef, useContext, useState, useEffect } from 'react';
 import {
   useBlockLayout,
   useExpanded,
   useFilters,
   useFlexLayout,
   useGroupBy,
   useResizeColumns,
   useRowSelect,
   useSortBy,
   useTable,
 } from 'react-table';
 import { Virtuoso } from 'react-virtuoso';
 import {
   CaretDownOutlined,
   CaretUpOutlined,
   LinkOutlined,
   SearchOutlined,
 } from '@ant-design/icons';
 import MosaicCellAddon from '../../../Addons/hcp360-cell/hcp360-cell';
 import ProfCellAddon from '../../../Addons/prof-cell/prof-cell';
 import { useBackTop } from '../../../Other/back-top-indicator/back-top-indicator';
 import EditableCell from '../editable-cell/editable-cell';
 import SelectedCell from '../selected-cell/selected-cell';
 import TotalCell from '../total-cell/total-cell';
 import ReactResizeObserver from 'rc-resize-observer';
 import styled from 'styled-components';
 import './custom-flex-table.module.scss';
 import { ConfigContext } from '@trinity-incyte/context';
import { QlikVisualizationInstance } from '@trinity-incyte/api-interfaces';
 
 const THeadStyles = styled.div`
     display: flex;
     flex-direction: column;
     flex: 0 0 auto;
 `;
 
 // Define a default UI for filtering
 function DefaultColumnFilter({
   column: { fieldExpression, id, isDimension, isMeasure },
 }) {
   return (
      <SearchOutlined
       data-action="open-search"
       data-fieldexpression={fieldExpression}
       data-id={id}
       style={{
         position: 'absolute',
         top: 2,
         right: 10,
         fontSize: '1.3em',
       }}
       data-isdimension={isDimension}
       data-ismeasure={isMeasure}
       name="search"
     />
   );
 }
 
 const defaultHiddenColumns = ['Cnt', 'Count']; // Any columns matching any of these names (case-sensitive) will be hidden
 
 /* eslint-disable-next-line */
 export interface CustomFlexTableProps {
   alternateCellColors: boolean,
   columns: any;
   data: any;
   rawData?: any;
   tableProps?: any;
   qViz: any;
   tableStyles: any;
   sticky: any;
   hideSearch?: any;
   noResize?: any;
   getMore?: (index: number) => void;
   dynamic?: boolean;
   triggerSort?: (cId: string) => void;
   initialState?: any;
   id?: string;
 }
 
 export function CustomFlexTable({
   alternateCellColors = true,
   columns,
   data,
   tableProps = { colProps: null },
   qViz,
   tableStyles,
   sticky,
   hideSearch = false,
   noResize = false,
   getMore,
   dynamic = false,
   initialState = {},
   triggerSort,
   id
 }: CustomFlexTableProps) {
   const defaultColumn = useMemo(
     () => ({
       // Let's set up our default Filter UI
       Filter: DefaultColumnFilter,
       // And also our default editable cell
       Cell: EditableCell,
       SelectedCell,
       TotalCell,
       minWidth: 60,
       // width: 150,
       maxWidth: 1200,
     }),
     []
   );
   // Use the state and functions returned from useTable to build your UI
 
   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
     totalColumnsWidth,
     setHiddenColumns,
     state: { hiddenColumns },
     setSortBy,
   } = useTable(
     {
       columns,
       data: data,
       defaultColumn,
       initialState: {
         ...initialState,
         hiddenColumns: defaultHiddenColumns
       },
     },
     useFilters,
     useGroupBy,
     useSortBy,
     useExpanded,
     useRowSelect,
     sticky ? useBlockLayout : useFlexLayout,
     useResizeColumns
   ) as any;
 
   const newHiddenColumns = [...defaultHiddenColumns];
   for (let ii = 0; ii < columns.length; ii += 1) {
     const col = columns[ii];
     if (col.hide) {
       newHiddenColumns.push(col.accessor);
     }
   }
 
   if (newHiddenColumns.length !== hiddenColumns.length) {
     setHiddenColumns(newHiddenColumns); // This will cause a re-render
   }
 
   const [sortedColumn, set_sortedColumn] = useState<{ id: string, desc?: boolean }>();
   const strLoadingMessage = "Loading Data";
   const [loadingMessage, set_loadingMessage] = useState(strLoadingMessage);
   const virtuoso = useRef(null);
   const parentRef = useRef<any>();
   const headerRef = useRef<any>();
   const scrollerRef = useRef<any>();
   const [scrollbarWidth, set_scrollbarWidth] = useState(0);
   const [set_scroller, onResizer, backTop] = useBackTop();
   const Config = useContext(ConfigContext);
   const profHeaders = Config.App.profHeaders;
 
   const setScrollbarWidth = useCallback(() => {
     if (!scrollerRef.current) return;
     set_scrollbarWidth(
       scrollerRef.current.offsetWidth - scrollerRef.current.clientWidth
     );
   }, [scrollbarWidth]);

   const dataLoaded = useEffect(() => {
    if (!qViz?.table) {
      set_loadingMessage(strLoadingMessage);
    } else if (qViz?.table.rowCount>0) {
      set_loadingMessage(strLoadingMessage);
    } else {
      set_loadingMessage("No Data");
    }
  }, [qViz.table.rowCount]);
 
   const scrollFn = useCallback(() => {
     const {
       style,
       children: [
         ,
         {
           scrollLeft,
           scrollTop,
           scrollWidth,
           scrollHeight,
           clientWidth,
           offsetWidth,
           clientHeight,
         },
       ], //Get the scrollLeft from virtuoso element
     } = parentRef.current || { children: [, {}] };
     const barSize = offsetWidth - clientWidth;
     headerRef.current?.scrollTo({
       left: scrollLeft,
       behavior: 'auto',
     });
     style.setProperty(
       '--vertical-scroll-position',
       barSize &&
       `calc(${(scrollTop / (scrollHeight - clientHeight)) * 100}%)`
     );
   }, []);
 
   useEffect(setScrollbarWidth, [scrollerRef]);
 
   useEffect(() => {
     if (sortedColumn?.id) {
       setSortBy([sortedColumn]);
     }
   }, [data]); //As more data comes in we check that the right column is sorted
 
   const ShouldSortIndicatorDisplay = ({ column, cId, dynamic, qViz }: {column: any, cId: any, dynamic: any, qViz: QlikVisualizationInstance }) => {
 
     let findCorrectHeader = qViz.table.headers.find((curr) => {
       if ((curr?.qDimensionInfo?.cId === cId) || (curr?.qMeasureInfo?.cId === cId)) return curr;  
     });
 
     let isDimension = findCorrectHeader.isDimension;
     if ((column.isSorted) || (dynamic && findCorrectHeader.isOrderedBy) || (!dynamic && findCorrectHeader.isOrderedBy)) {
       if (!isDimension) {
         if ((findCorrectHeader.qSortIndicator === 'D') || (!findCorrectHeader.qReverseSort)) {
           return ( <CaretDownOutlined style={{ flex: 'auto' }}/> )
         } else if (findCorrectHeader.qSortIndicator === 'A' || (findCorrectHeader.qReverseSort)) {
           return ( <CaretUpOutlined style={{ flex: 'auto' }}/> )
         }
       }
       if ((findCorrectHeader.qSortIndicator === 'D') || (findCorrectHeader.qReverseSort)) {
         return ( <CaretDownOutlined style={{ flex: 'auto' }}/> )
       } else if ((findCorrectHeader.qSortIndicator === 'A' && (!findCorrectHeader.qReverseSort)) || (!column.isSortedDesc)) {
         return ( <CaretUpOutlined style={{ flex: 'auto' }}/> )
       }
     } else {
       return null
     }
   }
   const CustomItem = ({ row, rowInd }) => (
     <>
       {row.cells.map((cell: any, cellInd) => {
         let Addon;
         let ProfAddon;
         // By default, will add ProfCard & Mosaic addons, but will be overwritten by lower if specified
         if (
           cell.column &&
           cell.column.Header &&
           cell.column.Header.toUpperCase &&
           profHeaders.indexOf(cell.column.Header.toUpperCase()) !== -1
         ) {
           Addon = (cellData) => (
             <MosaicCellAddon
               column={cellData.column}
               value={cellData.value}
             />
           );
           ProfAddon = (cellData) => <ProfCellAddon {...cellData} />;
         }
         if (
           tableProps.colProps &&
           tableProps.colProps[cellInd] &&
           tableProps.colProps[cellInd].addon
         ) {
           // Update this if column index ever comes from within a table prop
           Addon = tableProps.colProps[cellInd].addon;
         }
         if (
           tableProps.colProps &&
           tableProps.colProps[cellInd] &&
           tableProps.colProps[cellInd].profAddon
         ) {
           // Update this if column index ever comes from within a table prop
           ProfAddon = tableProps.colProps[cellInd].profAddon;
         }
         // Rewrite URLs to proper links
         let isUrl = false;
         if (cell.value && cell.value.indexOf('://') >= 0) {
           isUrl = true;
         }
 
         const elemNumber = cell?.row?.original?.qElemNumbers[cellInd];
         const columnId = cell?.row?.original?.syntheticColumnIds[cellInd];
         const cbProps = cell?.row?.original?.qStyles[cellInd];
         const isSelected = (cell?.row?.original?.qStates[cellInd] === Config.Qlik.qStateValues.SELECTED);
         const isTotal = cell?.row?.original?.isTotal;
         let content;
         if (isUrl) {
           content = (
             <a
               data-action="open-link"
               target="_blank"
               rel="noreferrer noopener"
               href={cell.value}
               style={{
                 textDecoration: 'underline',
               }}
             >
               {cell.value
                 .match(/(\=|\/)[a-zA-Z0-9\-]*$/g)
                 .join()
                 .replace(/(\=|\/)/g, '')}
               <LinkOutlined name="chain" />
             </a>
           );
         } else if (isSelected) {
           content = cell.render('SelectedCell', {
             editable: true,
             cbProps,
             rowInd,
             cellInd,
             Addon,
             ProfAddon,
             cell,
             tableStyles,
           });
         } else if (isTotal) {
           content = cell.render('TotalCell', {
             editable: true,
             cbProps,
             rowInd,
             cellInd,
             Addon,
             ProfAddon,
             cell,
             tableStyles,
           });
         } else {
           content = cell.render('Cell', {
             editable: true,
             cbProps,
             rowInd,
             cellInd,
             Addon,
             ProfAddon,
             cell,
             tableStyles,
           });
         }
 
         return (
           <div
             {...cell.getCellProps({
               style: {
                 justifyContent: 'flex-start',
                 alignItems: 'stretch',
                 display: 'flex',
               },
             })}
             className="td"
             data-row-id={rowInd}
             data-col-id={columnId}
             data-elem-number={elemNumber}
             data-action="qlik-select"
           >
             {content}
           </div>
         );
       })}
     </>
   );
 
   return (
     <div
       id={id}
       {...getTableProps()} //eslint-disable-line
       className={`table flex ${sticky ? 'sticky' : ''}`}
       ref={parentRef}
       style={{
         height: '100%',
         width: '99%',
         display: 'flex',
         flexDirection: 'column',
         lineHeight: '1rem',
         overflow: 'hidden'
       }}
     >
       <THeadStyles
         {...getTableBodyProps({
           style: { width: `calc(100% - ${scrollbarWidth}px)`},
         })} //eslint-disable-line
         className="thead"
         ref={headerRef}
         role="rowgroup"
       >
         {headerGroups.map((headerGroup) => (
           <div
             {...headerGroup.getHeaderGroupProps({
               style: {
                 justifyContent: 'flex-start',
                 alignItems: 'stretch',
                 display: 'flex',
               },
             })} //eslint-disable-line
             className="tr header scroll-y"
           >
             {headerGroup.headers.map((column: any, colIndex) => (
               <div {...column.getHeaderProps(column.getSortByToggleProps())}
                 onClick={
                   column.canSort
                     ? (e) => {
                         const classList = (e.target as HTMLElement).classList;
                         if ((classList.contains('header-cell')) || (classList.contains('th')) || (classList.contains('anticon'))) {
                           triggerSort(column.syntheticColumnId);
                           column.toggleSortBy();
                         }
                     }
                     : undefined
                 }
                 aria-sort={ 
                   column.isSorted || (dynamic &&qViz.table.headers[colIndex].isOrderedBy)
                     ? column.isSortedDesc || (dynamic &&qViz.table.headers[colIndex].qReverseSort)
                       ? 'descending'
                       : 'ascending'
                     : 'none'
                 }
                 className={`th${hideSearch || !column.canFilter || !column.isDimension ? ' no-filters' : ''}
                   ${noResize ? ' no-resize' : ''}`}
               >
                 <div className={`header-cell ${column.customClass || ''}`}
                   style={{ ...tableStyles.headerCellStyles, ...tableStyles.specificColHeaderCellStyle[colIndex]}} //eslint-disable-line
                 >
                   {column.fallbackTitle}
                   <ShouldSortIndicatorDisplay
                     dynamic={dynamic}
                     column={column}
                     qViz={qViz}
                     cId={column.syntheticColumnId}
                   />
                   {/* Render the columns filter UI */}
                   {!hideSearch && column.canFilter && column.isDimension && column.render('Filter')}
                 </div>
                 {!noResize && (
                   <div data-action="none" {...column.getResizerProps()}
                     className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                     onClick={(e) => {
                       e.stopPropagation();
                       e.preventDefault();
                     }}
                   />
                 )}
               </div>
             ))}
           </div>
         ))}
       </THeadStyles>
       <Virtuoso
         totalCount={qViz.table.rowCount}
         data={rows}
         endReached={(index) => {
           if (getMore && index < qViz.table.rowCount) {
             getMore(index);
           }
         }}
         ref={virtuoso}
         onScroll={scrollFn}
         className="scroll-container"
         scrollerRef={(elRef) => {
           scrollerRef.current = elRef;
           set_scroller(elRef);
         }}
         components={{
           List: React.forwardRef(({ style, children }, listRef) => (
             <ReactResizeObserver
               onResize={(rect) => {
                 onResizer(rect);
                 setScrollbarWidth();
               }}
             >
               <div
                 ref={listRef}
                 style={{ ...style, marginBottom: 0 }}
                 className="tbody"
                 role="rowgroup"
               >
                 {children}
               </div>
             </ReactResizeObserver>
           )),
           Item: (props) => (
             <div {...props} {...rows[props['data-index']]?.getRowProps()}
               className={`tr body alternateColorRows ${((alternateCellColors) && (props['data-index'] % 2) === 1) ? 'odd' : 'even'}`}
             />
           ),
           EmptyPlaceholder: () => (
             <>
             <Empty
               image={loadingMessage===strLoadingMessage?<Spin size="large" style={{marginTop:'3em'}} />:Empty.PRESENTED_IMAGE_SIMPLE}
               description={loadingMessage}
             />
             </>
           ),
         }}
         itemContent={(rowInd) => {
           const row = rows[rowInd];
           if (!row) return;
           prepareRow(row);
           return <CustomItem row={row} rowInd={rowInd} />;
         }}
         style={{ height: '100%', width: '100%' }}
       />
       <div className="tfoot" role="rowgroup"></div>
       {backTop}
     </div>
   );
 }
 
 export default CustomFlexTable;
