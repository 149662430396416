import React, { useContext, useEffect } from 'react'
import { ConfigContext, HCO360Context } from '@trinity-incyte/context'
import { KPIBox, QSMashupObject } from '@trinity-incyte/ui'
import { useRecoilValue } from 'recoil'
import { QSAppMetadataFamily } from '@trinity-incyte/recoil'

type HCO360ConnectionsParams = {
    brand: string
    groupId: string
}

export const HCO360Connections = ({ brand, groupId }: HCO360ConnectionsParams) => {

    if (!brand || !groupId) return;

    const hco360Context = useContext(HCO360Context)
    const Config = useContext(ConfigContext)
    const config = Config.Qlik.Prof360
    const { appId } = config.ids
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata

    useEffect(() => {        
        hco360Context.setActiveView('Connections')
        hco360Context.setBrand(brand)
		hco360Context.applyGroupId(groupId)
		hco360Context.setShowGroup(true)
	}, [])

    let hcoConnetionsObjects = Config?.App?.reports?.hco360Connections;
    let affiliatedHCPs = hcoConnetionsObjects.find(report => report.brand==brand.toUpperCase() && report.chart=="AffiliatedHCPs")?.friendlyName
    let connectionTypes = hcoConnetionsObjects.find(report => report.brand==brand.toUpperCase() && report.chart=="ConnectionTypes")?.friendlyName
    let connectedHCPDetails = hcoConnetionsObjects.find(report => report.brand==brand.toUpperCase() && report.chart=="ConnectedHCPDetails")?.friendlyName    

    if (!hcoConnetionsObjects) return;
    let layoutArray = [ ...Array(100).keys() ].map( i => i+1);

    let groupHeads = [];
    if (brand=='Jakafi') {
        // MPN
        groupHeads= [
            {
                columns: [2,3,4,5,6,7,8,9],
                title: 'Jakafi',
            },
            {
                columns: [10,11,12,13],
                title: 'Niktimvo',
            },
            {
                columns: [14,15,16,17,18],
                title: 'Pemazyre',
            },
            {
                columns: [19,20,21,22,23],
                title: 'Monjuvi',
            },
        ]
    }
    if (brand=='Pemazyre') {
        // CCA
        groupHeads= [
            {
                columns: [2,3,4,5,6],
                title: 'Pemazyre',
            },
            {
                columns: [7,8,9,10,11],
                title: 'Monjuvi',
            },
            {
                columns: [12,13,14,15,16,17,18,19],
                title: 'Jakafi',
            },
            {
                columns: [20,21,22,23],
                title: 'Niktimvo',
            },
        ]
    }
    if (brand=='Monjuvi') {
        // DLBCL
        groupHeads= [
            {
                columns: [2,3,4,5,6],
                title: 'Monjuvi',
            },
            {
                columns: [7,8,9,10,11],
                title: 'Pemazyre',
            },
            {
                columns: [12,13,14,15,16,17,18,19],
                title: 'Jakafi',
            },
            {
                columns: [20,21,22,23],
                title: 'Niktimvo',
            },
        ]
    }
    if (brand=='Niktimvo') {
        groupHeads= [
            {
                columns: [2,3,4,5],
                title: 'Niktimvo',
            },
            {
                columns: [6,7,8,9,10,11,12,13],
                title: 'Jakafi',
            },
            {
                columns: [14,15,16,17,18],
                title: 'Pemazyre',
            },
            {
                columns: [19,20,21,22,23],
                title: 'Monjuvi',
            },
        ]
    }

    return (
        <div style={{padding: '4px'}}>
            <div style={{display:'flex', width:'100%'}}>
                <div style={{ width:'46%', height:'42h'}}>
                    <QSMashupObject
                        appId={appId}
                        objectKey={ids.get(affiliatedHCPs)?.id}
                        mashupId={ids.get(affiliatedHCPs)?.id}
                        tableProps={{
                            colOverrides: [
                            {
                                columns: [0],
                                hStyles: { width: "28rem" },
                            },
                            ]
                        }}
                        isTable
                        compact
                        compactMargin
                    />
                </div>
                <div style={{flex: 1, width:'54%', height:'42vh', padding:'0px 8px 0px 18px'}}>
                <QSMashupObject
                        appId={appId}
                        tableProps={{
                            colOverrides: [
                            {
                                columns: [0],
                                hStyles: { width: "16rem" },
                            },
                            {
                                columns: [1],
                                hStyles: { width: "28rem" },
                            },
                            ]
                        }}
                        objectKey={ids.get(connectionTypes)?.id}
                        mashupId={ids.get(connectionTypes)?.id}
                        isTable
                        compact
                        compactMargin
                    />
                </div>
            </div>
            <div style={{display:'flex', width:'100%', paddingLeft:'6px', borderTop: '4px solid #BFD9F1', marginTop:'12px'}}>
                <div style={{width:'100%', height:'46vh'}}>
                <QSMashupObject
                        appId={appId}
                        groupHeads={groupHeads}
                        tableProps={{
                            colOverrides: [
                            {
                                columns: [0],
                                hStyles: { width: "20rem" },
                            },
                            {
                                columns: layoutArray,
                                hStyles: { width: "9rem" },
                            },
                            ]
                        }}
                        objectKey={ids.get(connectedHCPDetails)?.id}
                        mashupId={ids.get(connectedHCPDetails)?.id}
                        isTable
                        compact
                        compactMargin
                    />
                </div>
            </div>
        </div>
    )
}
