import React, { useState, useRef } from 'react';
import {
	Button as AntButton,
	Space,
	Row,
	Col,
	Tag,
	Divider,
	Badge,
} from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dismissedAlerts as dismissedAlertsAtom } from '@trinity-incyte/recoil';
import { generate as generatePalette } from '@ant-design/colors';
import { HCP360Cell, ListCard, ListCardLabel, RecentEventsTable, TimelineCell, DismissCell, UnDismissCell } from '@trinity-incyte/ui';
import styled from 'styled-components';
import { DownCircleFilled, LeftCircleOutlined, StarFilled } from '@ant-design/icons';

declare var window: any;

const LabelsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const Alert = ( props ) => {
	const [showItems, set_showItems] = useState(props.showItems);
	const [showActions, set_showActions] = useState(props.showActions);
	const measureRef: any = useRef();
	const {
		labels,
		icon,
		key,
		date,
		prof,
		profId,
		brandName,
		alertType,
		alertSubtype,
		alertTrigger,
		employee,
		supplementalLabel,
		supplementalTriggerText,
		alertKey,
		count,
		style,
		recentAlertFlag
	} = props;

	const productColors = {
			'monjuvi': '#256678',
			'jakafi': '#6db73c',
			'pemazyre': '#ed6551',
			'niktimvo': '#563496',
			'zynyz': '#004D6A',
	};
	const dismissedAlerts = useRecoilValue(dismissedAlertsAtom);
	const setDismissedAlerts = useSetRecoilState(dismissedAlertsAtom);

	let alertParseDbId = dismissedAlerts.find(x => x.AlertKey === alertKey)?.AlertParseDbId;
	let productColorKey = brandName?.toLowerCase();

	const BrandLabel = () => {
		return (
			<ListCardLabel component={
				<AntButton
					size="small"
					style={{
						backgroundColor: productColors[productColorKey] || 'green',
						borderColor: productColors[productColorKey] || 'green'
					}}
				>
					{brandName}
				</AntButton>}>
			</ListCardLabel>
		)
	}

	if (supplementalTriggerText=="-----") {
		return (<></>);
	} else {
		return (
			// bodyStyle to edit the inner div that gets added with AntD cards
			<ListCard>
				<div style={{ position: "absolute", left:"-5px", top:"-3px"}}>
					<LabelsContainer>
						{(recentAlertFlag === "Y") ? (
						<Badge count={<StarFilled style={{ left: '-10px', color: 'gold', stroke: productColors[productColorKey], strokeWidth: '40' }}/>} style={{ width: '10px', height: '10px', borderColor: productColors[productColorKey] }}>
							<BrandLabel />
						</Badge>
						) : (
							<BrandLabel />
						)}
						{(labels) && (
							<Space style={{ marginTop: '-1px', marginLeft: '-4px' }}>
								{labels.map((val) => {
									return (
										<Tag
											style={val.style}
											icon={val.icon}
											key={val.content}
										>
											{val.content}
										</Tag>
									)
								})}
							</Space>
						)}
					</LabelsContainer>
				</div>
					<div style={{color: '#595959'}} className={alertParseDbId?`no-padding alert-fill`:`no-padding`}>
						<Row style={{width:"100%", paddingLeft:"2%", paddingBottom:'0.25rem'}}>
							<Col span={17} style={{ paddingTop: '1.8rem', paddingBottom: (employee && employee !== '-') ? null : '0.2rem' }}>
								{/* Main alert text */}
								<div className="alertsProfName" style={{fontSize: '1.2rem', fontWeight:700, fontStyle:'italic', lineHeight: '1rem', padding: '0rem 0rem 0.45rem 0rem', border: 'none' }}>
									{`${prof || ''}`}
								</div>
								{/* CSS Grid makes this *SO MUCH EASIER*!!!! */}
								<div style={{ display: 'grid', gridTemplateColumns: `${(supplementalLabel !== '-') ? '5fr 1fr' : '1fr'}`, fontSize: '1rem' }}>

									<div style={{ gridColumn: 'auto' }}>
										<div className='alertsAlertTrigger' style={{padding: '0rem 0rem 0.4rem 0rem', border: 'none', lineHeight: '1rem',}}>
										{alertTrigger}
										</div>

										{(supplementalTriggerText !== '-') && (
										<div className="alertSupplementalTriggerText" style={{lineHeight: '1rem'}}>
											{supplementalTriggerText}
										</div>
										)}
									</div>

									{(supplementalLabel !== '-') && (
										<div style={{ gridColumn: 'auto' }}>
											<div 
											className = "alertTriggeredDate"
											style={{
												position: 'absolute',
												bottom: '0',
												right: '1px',
												color: '#595959',
												backgroundColor:'rgb(250, 250, 250)',
												fontSize: '1rem',
												fontWeight: 'bolder',
												padding: '0px 4px'
											}}>
												{supplementalLabel}
											</div>
										</div>
									)}
								</div>
							</Col>
							{/* Side buttons */}
							<Col style={{ textAlign:"left", paddingLeft: "3px" }} span={7}>
								<Row>
									<Col flex='5px'>
										<Divider type="vertical" style={{position: "absolute", margin:"0px", height:"100%"}}></Divider>
									</Col>
									<Col flex="auto" style={{padding:'5px 0 0 0'}}>
										<Space direction="vertical" size={4} style={{ width: '100%' }}>
											<Row align="bottom" justify="space-between" gutter={4}>
												<Col flex="auto">
													<HCP360Cell value={profId} extraClass="button-cell-home" size="small" />
												</Col>
												<Col flex="auto" style={{ paddingRight: '4px' }}>
													<TimelineCell extraClass="button-cell-home" value={profId} size="small" />
												</Col>
											</Row>
											<Divider type="horizontal" style={{ margin: '0' }} />
											<Row align="bottom" justify="space-between" gutter={4}>
												<Col flex="auto">
														<AntButton
															className='button-cell green-cell button-cell-home'
															data-action="self"
															size="small"
															style={{ backgroundColor: 'transparent', marginLeft: '0px' }} 
															onClick={(event) => {
																window.appInsights?.trackEvent({name: `Button Clicked - Recent Events - Toggles Showing or Hiding Recent Events inside Alert` });
																props.setShowItems(!showItems);
																set_showItems(!showItems);
															}}
														>Recent Events{(showItems) ? (<DownCircleFilled style={{ padding: "0px" }} />) : (<LeftCircleOutlined style={{ padding: "0px" }} />)}</AntButton>
												</Col>
												<Col flex="auto" style={{ paddingRight: '4px' }}>
													{(!alertParseDbId)&&
														<DismissCell value={profId} alertKey={alertKey} alertType={alertType} />
													}
													{(alertParseDbId)&&
														<UnDismissCell value={profId} alertParseDbId={alertParseDbId} alertKey={alertKey} alertType={alertType} />
													}
												</Col>
											</Row	>
										</Space>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				<div style={{ display: showActions || showItems ? '' : 'none', width: '100%', padding:'10px', overflowY: 'auto', backgroundColor: '#d0d9e0' }}>
					<div ref={measureRef}>
						{showItems && (<RecentEventsTable profId={profId} />)}
					</div>
				</div>
			</ListCard>
		);
	
	}

}; 

export default Alert;