import React, {useContext, useEffect, useState} from 'react';
import { activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { useRecoilValue } from 'recoil';
import JakafiOverview from './JakafiOverview';
import JakafiTrends from './JakafiTrends';
import MonjuviOverview from './MonjuviOverview';
import PemazyreOverview from './PemazyreOverview';
import MonPemTrends from './MonPemTrends';
import OCNEOverview from './OCNEOverview'
import { RGLGrid as Grid, LeftSlider, NavGroupMosaic, TabGroup } from '@trinity-incyte/ui';
import NiktimvoOverview from './NiktimvoOverview';
import NiktimvoTrends from './NiktimvoTrends';
import { hasFeature } from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';

const OverviewIndex = (props) => {
	const Config = useContext(ConfigContext)
	var tabsByTeam = {
		HO1: [{name: "Jakafi Overview", comp: JakafiOverview}, {name: "Trends", comp: JakafiTrends}],
		HO2: [{name: "Jakafi Overview", comp: JakafiOverview}, {name: "Trends", comp: JakafiTrends}],
		HO3: [{name: "Monjuvi Overview", comp: MonjuviOverview}, {name: "Pemazyre Overview", comp: PemazyreOverview}, {name: "Trends", comp: MonPemTrends}],
		OCNE: [{name: "OCNE Overview", comp: OCNEOverview}]
	};
	if (hasFeature(Config.App.features, "NIKTIMVO")) {
		tabsByTeam.HO2[1].name = "Jakafi Trends"
		tabsByTeam.HO2.push({name: "Niktimvo Overview", comp: NiktimvoOverview})
		tabsByTeam.HO2.push({name: "Niktimvo Trends", comp: NiktimvoTrends})
	}
	const profId = props?.profId;
	const urlSuffix = profId ? `/${profId}` : '';
	const gridLayout = {
		responsive: [
			{
				w: 5,
				i: 'Mosaic Overview Prof Box',
				props: { profId: profId }
			},
			{
				w: 19,
				i: 'Overview Tab Group',
				content: { component: TabGroup },
				props: { tabsByTeam, ...props }
			}
		]
	}

	return (
		<>
			<NavGroupMosaic urlSuffix={urlSuffix} />
			<Grid
				title="Mosaic Overview"
				className="mosaicOverviewLayout"
				layout={gridLayout}
			/>
			<LeftSlider />
		</>
	);
};

export default OverviewIndex;
