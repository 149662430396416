import React, { useContext, useEffect } from 'react'
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ConfigContext, HCO360Context } from '@trinity-incyte/context';
import { TabDef, TabGroup } from "@trinity-incyte/ui"
import HCO360ProductJakafiSales from './HCO360ProductJakafiSales'
import HCO360ProductJakafiInteractions from './HCO360ProductJakafiInteractions'
import HCO360ProductPemazyreSales from './HCO360ProductPemazyreSales'
import HCO360ProductPemazyreInteractions from './HCO360ProductPemazyreInteractions'
import HCO360ProductMonjuviInteractions from  './HCO360ProductMonjuviInteractions'
import HCO360ProductMonjuviSales from  './HCO360ProductMonjuviSales'
import HCO360ProductNiktimvoSales from './HCO360ProductNiktimvoSales';
import HCO360ProductNiktimvoInteractions from './HCO360ProductNiktimvoInteractions';
declare const Mosaic: MosaicGlobal;

type HCO360ProductHomeParams = {
    brand: string
    groupId: string
}

export const HCO360ProductHome = ({ brand, groupId }: HCO360ProductHomeParams) => {
    const hco360Context = useContext(HCO360Context)
    const Config = useContext(ConfigContext);
    
    useEffect(() => {
        hco360Context.setActiveView('Overview')
        hco360Context.setBrand(brand)
		hco360Context.applyGroupId(groupId)
		hco360Context.setShowGroup(true)
	}, [])

    useEffect(() => {
        hco360Context.setBrand(brand)
    }, [brand])

    const tabs = new Array<TabDef>()

    const clearFilters = () => {
        const hcp360App = Mosaic.Qlik.app[Config.Qlik.Prof360.ids.appId];
        if ( hcp360App ) {
            hcp360App.field( '%GP_ID' ).clearOther( true );
        }
    };

    switch (brand) {
        default:
        case 'Jakafi':
            tabs.push({name: "Jakafi Sales", comp: HCO360ProductJakafiSales}, {name: "Jakafi Interactions", comp: HCO360ProductJakafiInteractions})
            break
        case 'Pemazyre':
            tabs.push({name: "Pemazyre Sales", comp: HCO360ProductPemazyreSales}, {name: "Pemazyre Interactions", comp: HCO360ProductPemazyreInteractions})
            break
        case 'Monjuvi':
            tabs.push({name: "Monjuvi Sales", comp: HCO360ProductMonjuviSales}, {name: "Monjuvi Interactions", comp: HCO360ProductMonjuviInteractions})
            break
        case 'Niktimvo':
            tabs.push({name: "Niktimvo Sales", comp: HCO360ProductNiktimvoSales}, {name: "Niktimvo Interactions", comp: HCO360ProductNiktimvoInteractions})
            break
    }

    return (
        <TabGroup tabs={tabs} onTabChange={clearFilters} />
    )
}
