import { ConfigContext, HCO360Context } from '@trinity-incyte/context';
import React, { useContext, ReactNode, useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Menu, Row } from 'antd';
import styled from 'styled-components';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { CenterV, TopNav, TopNavDropdown } from '@trinity-incyte/ui';
import { CaretDownFilled } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import {
	QlikUser as qlikUserAtom,
} from '@trinity-incyte/recoil';
declare const Mosaic: MosaicGlobal;

declare var window: any;

const MAX_RECENT_ITEMS = 10

/* eslint-disable-next-line */

const NavButtonStyle = {
	borderColor: '#005CAB',
	fontSize: '1.2rem',
	fontWeight: 700,
    paddingLeft: '2rem',
    paddingRight: '2rem'
}

type RecentlyViewedItems = {
    name: string
    id: string
}

export interface NavGroupMosaicProps {
	urlSuffix?: string;
	showClear?: boolean;
	incyteActivities?: any[];
	publicActivities?: any[];
	size?: any;
	children?: ReactNode;
	hideConnections?: boolean
}

export function HCO360TopNav(props: NavGroupMosaicProps) {
	const Config = useContext(ConfigContext);
	const hco360Context = useContext(HCO360Context);
    const [recentlyViewedItems, setRecentlyViewedItems] = useState<Array<RecentlyViewedItems>>(JSON.parse(localStorage.getItem('HCO360RecentGroups')) || []);
	const config = Config.Qlik.Prof360;

	const history = useHistory();
    
	const userData = useRecoilValue(qlikUserAtom);
	const dataDateJakPem = userData.dataDate.jakPem;

    useEffect(() => {
		if (hco360Context.state.groupId) {
			
			// form the new entry object
			let newEntry:RecentlyViewedItems = { name: hco360Context.state.groupData.name, id: hco360Context.state.groupId };

			// initialize the new array
			let newRecentlyViewedItems = [...recentlyViewedItems];

			let recentItemsFoundIndex = newRecentlyViewedItems.find((recentItem) => recentItem.id == hco360Context.state.groupId);

			// if it's already in there, remove it
			let itemInListIndex = newRecentlyViewedItems.indexOf(recentItemsFoundIndex);

			if (itemInListIndex!=-1) {
				newRecentlyViewedItems.splice(itemInListIndex, 1);
				setRecentlyViewedItems(newRecentlyViewedItems);
			}

			// now add it at the beginning
			newRecentlyViewedItems = [newEntry, ...newRecentlyViewedItems];

			// get rid of the one at the end
			if (newRecentlyViewedItems.length > MAX_RECENT_ITEMS) {
				newRecentlyViewedItems.pop();
			}

			localStorage.setItem('HCO360RecentGroups', JSON.stringify(newRecentlyViewedItems));
			setRecentlyViewedItems(newRecentlyViewedItems);

		} else {
			return;
		}
    }, [hco360Context.state.groupId]);

    let dropDownItemsRecent = recentlyViewedItems.map((ri) => {
        return { text: `${ri.name} (${ri.id})`, to: `/HCO360/${ri.id}`, key: `recent_${ri.id}`, onClick: ()=> { 
            hco360Context.applyGroupId(ri.id)
        }, type: 'item' }
    })

	if (dropDownItemsRecent.length==0) {
        dropDownItemsRecent = [{ text: 'No recent items', onClick: () => {}, key:'no recent items', to: '', type: 'other' }]
	}

	let dropDownItems = [
        { text: 'Search', to: '/HCO360/', onClick: () => {}, type: 'item', key:'HCO 360 Search Nav' },
        { text: '', to: '', onClick: () => {}, type: 'divider', key:'HCO 360 Search Nav To' },
        { text: 'Recent HCO360:', to: '', onClick: null, type: 'header', key:'Recent HCO 360 Search Nav' },
        ...dropDownItemsRecent
	];

	return (
		<TopNav
			{...props}
            dataDate={dataDateJakPem}
            dataDateLabel={"Data Through"}
			dropdownText={(<span>{'HCO 360°'}</span>)}
			dropdownItems={dropDownItems}
			config={config}
            showLastRX={true}
		>
			{( hco360Context.state.groupId) && (
                <>
                    <TopNavDropdown
                        style={NavButtonStyle}
                        text={<span>{`Product: ${hco360Context.state.brand ? hco360Context.state.brand : ' All'}`}</span>}
                        items={[
                            {
                                text: "All Brands",
                                onClick: () => {
                                    window.appInsights?.trackEvent({name: `Link Clicked - HCO360 - All Brands` })
                                },
                                to: `/HCO360/${hco360Context.state.groupId}/`,
                                type: 'item'
                            }, {
                                text: "Jakafi",
                                onClick: () => {
                                    window.appInsights?.trackEvent({name: `Link Clicked - HCO360 - Jakafi` })
                                },
                                to: `/HCO360/${hco360Context.state.groupId}/Jakafi/`,
                                type: 'item'
                            }, {
                                text: "Pemazyre",
                                onClick: () => {
                                    window.appInsights?.trackEvent({name: `Link Clicked - HCO360 - Pemazyre` })
                                },
                                to: `/HCO360/${hco360Context.state.groupId}/Pemazyre/`,
                                type: 'item'
                            }, {
                                text: "Monjuvi",
                                onClick: () => {
                                    window.appInsights?.trackEvent({name: `Link Clicked - HCO360 - Monjuvi` })
                                },
                                to: `/HCO360/${hco360Context.state.groupId}/Monjuvi/`,
                                type: 'item'
                            }, {
                                text: "Niktimvo",
                                onClick: () => {
                                    window.appInsights?.trackEvent({name: `Link Clicked - HCO360 - Niktimvo` })
                                },
                                to: `/HCO360/${hco360Context.state.groupId}/Niktimvo/`,
                                type: 'item'
                            }
                        ]}
                    >
                    </TopNavDropdown>
                    <CenterV style={NavButtonStyle} key="HCO360 Product Summary" keyToUse="HCO360 Product Summary inner" className={`topNavItem interactable${hco360Context.state.activeView == 'Overview' ? ' active' : ''}`}>
                        <div
                            onClick={() => { 
                                if (hco360Context.state.brand == null) {
                                    history.push(`/HCO360/${hco360Context.state.groupId}/`)
                                } else {
                                    history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/`)
                                }
                                window.appInsights?.trackEvent({name: `Link Clicked - HCO360 Product Summary` });
                            }}
                        >
                            Overview
                        </div>
                    </CenterV>
                    {(hco360Context.state.brand) && (
                        <>
                            <CenterV style={NavButtonStyle} key="HCO360 HCPs" keyToUse="HCO360 HCPs inner" className={`topNavItem interactable${hco360Context.state.activeView == 'HCPs' ? ' active' : ''}`}>
                                <div
                                    onClick={() => { 
                                        history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/HCPs`);
                                        window.appInsights?.trackEvent({name: `Link Clicked - HCO360 HCPs Button Clicked` })
                                    }}
                                >
                                    HCPs
                                </div>
                            </CenterV>
                            <CenterV style={NavButtonStyle} key="HCO360 Connections" keyToUse="HCO360 Connections Inner" className={`topNavItem interactable${hco360Context.state.activeView == 'Connections' ? ' active' : ''}`}>
                                <div
                                    onClick={() => { 
                                        history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/Connections`);
                                        window.appInsights?.trackEvent({name: `Button Clicked - HCO360 Connections` });
                                    }}
                                >
                                    Connections
                                </div>
                            </CenterV>
                            {(!userData.suppressSalesData) && (
                                <CenterV style={NavButtonStyle} key="HCO360 Reports" keyToUse="HCO360 Reports inner" className={`topNavItem interactable${hco360Context.state.activeView == 'Reports' ? ' active' : ''}`}>
                                    <div
                                        onClick={() => { 
                                            history.push(`/HCO360/${hco360Context.state.groupId}/${hco360Context.state.brand}/Reports`);
                                            window.appInsights?.trackEvent({name: `Button Clicked - HCO360 Reports` });
                                        }}
                                    >
                                        Account Details
                                    </div>
                                </CenterV>
                            )}
                        </>
                    )}
                </>
			)}
			{props.children}
		</TopNav>
	);
};

export default HCO360TopNav;
