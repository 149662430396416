import React, { useContext, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
    activeTeam as activeTeamAtom,
    IncyteTeam,
    QlikUser as QlikUserAtom,
    dismissedAlerts as dismissedAlertsAtom,
} from '@trinity-incyte/recoil'

import styled from 'styled-components'
import AlertsList from './AlertsList'
import { CheckCircleOutlined } from '@ant-design/icons';
import AlertsContext from './AlertsContext'


declare var window: any

const AlertsActivities = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex:1 auto;
`;

export const AlertsPane = () => {
    const alertsContext = useContext(AlertsContext)
    const {events, counts} = alertsContext.state

	const activeTeam = useRecoilValue<IncyteTeam>(activeTeamAtom);
    const [selectedCompany] = useState<boolean>(false);
    const [selectedTeam] = useState<boolean>(false);
    const [selectedGroup, set_selectedGroup] = useState<string>();
    const [selectedType, set_selectedType] = useState<string>();
    const [selectedIndications, set_selectedIndications] = useState<Array<string>>([]);
    const [defaultGroup, set_defaultGroup] = useState<string>();
    const [defaultType, set_defaultType] = useState<string>();
    const userData = useRecoilValue(QlikUserAtom);
    const dismissedAlerts = useRecoilValue(dismissedAlertsAtom);

    // styles for alert tab interface
    const baseColor = `#eeeeee`;

    const selectedFontColor:string = `#fff`;
    const selectedBackgroundColor:string = `#005cad`;
    const selectedBorderColor:string = `#999999`;

    const unselectedFontColor:string = `#eee`;
    const unselectedBackgroundColor:string = `#ccc`;
    const unselectedBorderColor:string = `#BFD9F1`;

    let highToLowGraident = `linear-gradient(90deg, rgba(0,92,173,1) 0%, rgba(0,92,173,1) 50%, rgba(238,238,238,1) 100%)`;
    let lowToHighGraident = `linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(0,92,173,1) 50%, rgba(0,92,173,1) 100%)`;

    const alertGroupsOrder = {
        'ALERTS': 1,
        'NOTIFICATIONS': 2,
        '-': 3
    }
    
    const alertTypesOrder = {
        'VIT Leads': 1,
        'CLINICAL': 2,
        'PREDICTED MCE': 3,
        'CCA BARRIER': 4,
        'NPP': 5,
        'BARRIER': 6,
        'DIAGNOSTIC': 7,
        'CCA DIAGNOSTIC': 8,
        'IMPORT': 9,
        'OTHER': 10
    };

    const alertIndicationsOrder = {
        'MF': 1,
        'PV': 2,
        'GVHD': 3,
        'CGVHD': 4,
        'CCA': 5,
        'DLBCL': 6,
        'General': 7,
    }

    const getAlertsKeys = (arrEvents:any) => {
        let eventKeyList = arrEvents.All?.map(r => r.alertKey) ?? []; 
        return eventKeyList;
    }
    const alertsKeys = useMemo(() => getAlertsKeys(events), [events]); 


    const sortTypeCounts = (group) => (counts?.[group] && Object.values<any>(counts[group].types).sort((a,b) => alertTypesOrder[a.name] - alertTypesOrder[b.name])) || []

    const sortedAlertCounts = (counts && Object.values<any>(counts).sort((a,b) => alertGroupsOrder[a.name] - alertGroupsOrder[b.name])) || []
    const sortedTypeCounts = sortTypeCounts(selectedGroup || defaultGroup)
    const sortedIndicationCounts = (counts?.[selectedGroup || defaultGroup]?.types[selectedType || defaultType] && Object.values<any>(counts[selectedGroup || defaultGroup].types[selectedType || defaultType].indications).sort((a,b) => alertIndicationsOrder[a.name] - alertIndicationsOrder[b.name])) || []
    // if there are no selections, set them all to on
    if ((sortedIndicationCounts?.length > 1) && (selectedIndications.length==0)) {
        let newSelectionsForNone = sortedIndicationCounts?.map((indication) => indication.name);
        set_selectedIndications(newSelectionsForNone);
    }
    if (!selectedGroup && sortedAlertCounts?.length > 0 && defaultGroup !== sortedAlertCounts[0].name) {
        set_defaultGroup(sortedAlertCounts[0].name);
    }
    const alertsTabs = () => {
    return (
        <div style={{}} key="alerts tab container">
        <div key="alerts tab selected" style={{display: 'flex', flexDirection: 'row', backgroundColor: baseColor}}>
            { sortedAlertCounts?.length > 1 && sortedAlertCounts.map((group, index) => {
                if (group) {
                    const rowHeader = group.name
                    const count = group.count

                    let isSelected = ((selectedGroup === rowHeader) || (!selectedGroup && defaultGroup === rowHeader)) ? true : false;
                    let baseFirstRowStyle:React.CSSProperties = {
                        width: `${100/sortedAlertCounts.length}%`,
                        textAlign: 'center',
                        cursor: 'pointer',
                        padding: '8px 5px 8px 5px',
                        whiteSpace: 'nowrap',
                        borderRadius: '8px 8px 0px 0px',
                    };
    
                    return (
                        <div 
                            key={`nav_group_rowHeader_${index}`}
                            onClick={() => { 
                                set_selectedGroup(rowHeader)
                                set_selectedType(sortTypeCounts(rowHeader)[0]?.name)
                                set_selectedIndications([])
                                }}
                            style={(isSelected) ? {
                                ...baseFirstRowStyle,
                                backgroundColor: selectedBackgroundColor,
                                borderColor: `${selectedBorderColor}`,
                                color: selectedFontColor
                            } : {
                                ...baseFirstRowStyle,
                                borderColor: `${unselectedBorderColor}`,
                                borderWidth: `1px 1px 0px 1px`, 
                                borderStyle: `solid`
                            }}>
                            <span>{rowHeader} - {count.toLocaleString()}</span>
                        </div>
                    )
                }
            })}
        </div>
        {/* <div style={{display: 'flex', flexDirection: 'row'}}> */}
        <div key={"alerts tab flex row"} 
            style={{display: 'flex', flexDirection: 'row', 
                    borderRight: `${(selectedGroup!=sortedAlertCounts[sortedAlertCounts?.length-1]?.name)?'1px solid '+unselectedBorderColor:'0px solid '+baseColor}`,
                    borderLeft: `${(selectedGroup==sortedAlertCounts[sortedAlertCounts?.length-1]?.name)?'1px solid  '+unselectedBorderColor:'0px solid '+baseColor}`, 
                    background: (selectedGroup!=sortedAlertCounts[sortedAlertCounts?.length-1]?.name)?highToLowGraident:lowToHighGraident}}>
        { sortedTypeCounts.map((type, index) => {
            if (type) {
                const rowHeader = type.name
                const count = type.count
                let selectdAlertTypeDismissedAlertCount = dismissedAlerts.filter(x => x.AlertType===rowHeader && alertsKeys.includes(x.AlertKey)).length;
                let totalDismissed = selectdAlertTypeDismissedAlertCount;
                let totalUndismissed = count - selectdAlertTypeDismissedAlertCount;

                if (index === 0 && !selectedType && defaultType !== rowHeader) {
                    set_defaultType(rowHeader);
                }
                let isSelected = ((selectedType === rowHeader) || (!selectedType && defaultType === rowHeader)) ? true : false;
                let baseSecondRowStyle:React.CSSProperties = {
                    cursor:'pointer',
                    textAlign:'center',
                    width:`${100/sortedTypeCounts.length}%`,
                    padding:'9px 0px 0px 0px',
                    whiteSpace:'nowrap',
                    borderRadius: '12px 12px 0px 0px',
                    borderWidth: '1px 1px 0px 1px',
                    borderStyle: 'solid',
                    height: '40px'
                };

                return (
                    <div 
                        key={`alert_tab_index_${index}`}
                        onClick={() => { 
                            window.appInsights?.trackEvent({name: `Button Clicked - ${rowHeader} - ${activeTeam} - Alert Clicked` })
                            set_selectedType(rowHeader)
                            set_selectedIndications([])
                            }}
                            // key={rowHeader}
                            style={
                                (isSelected) ? {
                                    ...baseSecondRowStyle,
                                    borderColor: `${selectedBorderColor}`,
                                    color: selectedFontColor,
                                    backgroundColor: selectedBackgroundColor,      
                                    boxShadow: `0 0 10px 0px rgba(0,0,0,0.3)`,      
                                } : {
                                    ...baseSecondRowStyle,
                                    borderColor: '#ccc',
                                    color: `#666`,
                                    backgroundColor: `#ddd`,                
                                }
                                
                            }>
                        <span key={`alert_outer_key_${index}`}>
                            <div key={`alert_node_${index}`}>
                                {rowHeader} - {totalUndismissed.toLocaleString()}
                                <span className="alert-inner" key={`alertSp_${index}`} title={`${totalDismissed} Dismissed`}> ({totalDismissed})</span>
                            </div>
                        </span>
                    </div>
                )
            }
        })}
        </div>

        <div style={{display: 'flex', flexDirection: 'row'}}>
        { sortedIndicationCounts?.length > 1 && sortedIndicationCounts?.map((indication, index) => {
            if (indication) {
                const rowHeader = indication.name
                const count = indication.count
                let baseThirdRowStyle:React.CSSProperties = {
                    width: `${100/sortedIndicationCounts.length}%`,
                    fontWeight: 700,
                    maxWidth: '20%',
                    textAlign: 'center',
                    cursor: 'pointer',
                    padding: '8px 5px 8px 5px',
                    whiteSpace: 'nowrap',
                    borderRadius: '12px',
                    margin: '3px 3px'
            };

                let isSelected = selectedIndications.includes(rowHeader) || selectedIndications.length==0
                
                return (
                    <div 
                        key={rowHeader}
                        className="alerts-card"
                        onClick={() => {
                            let newSelections
                            if (selectedIndications.includes(rowHeader)) {
                                newSelections = selectedIndications.filter(i => i !== rowHeader)
                            } else {
                                newSelections = [...selectedIndications]
                                newSelections.push(rowHeader)
                            }
                            set_selectedIndications(newSelections)
                        }}
                        style={(isSelected) ? {
                            ...baseThirdRowStyle,
                            color: selectedBackgroundColor,
                            border: `1.4px solid ${selectedBackgroundColor}`,
                            backgroundColor: `#FAFAFA`,
                        } : {
                            ...baseThirdRowStyle,
                            background: `#ddd`,
                            border: `1px solid ${unselectedBorderColor}`,
                            fontWeight: 'normal'
                        }}>
                        <span>
                            {
                              isSelected && (
                                <CheckCircleOutlined style={{color: `green`, margin:'0em 0.5em 0em 0.5em', fontSize: '0.9em'}} />
                              )
                            }
                            {rowHeader} - {count.toLocaleString()}
                        </span>
                    </div>
                )
            }

        })}
        </div>
        </div>
        )
    }

    return (
        <AlertsActivities>
            {alertsTabs()}
            <div className='alerts-pane-wrapper'>
                <AlertsList
                    items={events.All}
                    selectedCompany={selectedCompany}
                    selectedTeam={selectedTeam}
                    selectedType={selectedType || defaultType}
                    selectedGroup={selectedGroup || defaultGroup}
                    selectedIndications={selectedIndications}
                />
            </div>
        </AlertsActivities>
    );
}