import React from 'react';
import { NavLink } from 'react-router-dom';
import './nav-link-wrapper.module.scss';

declare var window: any;

/* eslint-disable-next-line */
export interface NavLinkWrapperProps {}

export function NavLinkWrapper(props: NavLinkWrapperProps | any) {
    return (
        <NavLink
            {...props}
            role="link"
            className="clickable"
            style={props.style || { height: '100%', width: '100%' }}
            to={props.to || props.path}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
                window.appInsights?.trackEvent({name: `Link Clicked - ${props.val || props.to || props.path} - Nav Link Wrapper Clicked` });
            }}
        />
    );
}

export default NavLinkWrapper;
