import React from 'react';
import {
  Typography,
  Row,
  Col,
  Button as AntButton,
} from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { QSMashupObject } from '@trinity-incyte/ui';
import { useRecoilValue } from 'recoil';
import { QSAppMetadataFamily, activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { PathGenerators } from '@trinity-incyte/utils';
import PemiBoxStyles from './PemiBox.module.scss';

declare var window: any;

const { Title } = Typography;

const PemiBox = (props) => {
  const { config } = props;
  const { appId } = config.ids;
  const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const activeTeam = useRecoilValue(activeTeamAtom);
  const { IDsTable: ids } = metadata;

  const items = [
    { to: PathGenerators.FieldSalesPathGenerator(activeTeam, '/Pemazyre/Trends/1'), name: 'Mosaic_Main - Pemazyre_kpi_New Prescribers (R12M)', color: 'black' },
    { to: PathGenerators.FieldSalesPathGenerator(activeTeam, '/Pemazyre/Trends/0'), name: 'Mosaic_Main - Pemazyre_kpi_New Patients (R12M)', color: 'black' },
  ];

  return (
    <>
      <Title level={4} className={PemiBoxStyles.pemiBoxHeader}>
        <NavLink
          to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/Pemazyre/Trends/0")}
          onClick={() => window.appInsights?.trackEvent({name: `Link Clicked - Pemazyre in PemiBox` })}
          >
          <span>Pemazyre</span>
          <LinkOutlined />
        </NavLink>
      </Title>
      <Row gutter={[8, 8]} style={{ height: 'calc(100% - 3rem)' }}>
        {items.map((val) => (
          <Col span={12} style={{ height: '100%' }} key={val.name}>
            <AntButton
              type="text"
              block
              style={{ height: '100%' }}
            >
              <NavLink
                to={val.to}
                onClick={() => window.appInsights?.trackEvent({name: `Link Clicked - Pemazyre Statistic Link - ${val.to}` })}
                >
                <QSMashupObject
                  isText size="small"
                  color={val.color}
                  appId={appId}
                  mashupId={ids.get(val.name)?.id}
                  contentClass="no-margin"
                />
              </NavLink>
            </AntButton>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PemiBox;