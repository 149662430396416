import React from 'react';
import {
  Typography,
  Row,
  Col,
  Button as AntButton
} from 'antd';
import { generate as generatePalette } from '@ant-design/colors';
import { NavLink } from 'react-router-dom';

declare var window: any;

const EventBox = () => {
  const items = [
    {
      key: 'Current 4 Events',
      content: (
        <NavLink
          to="/Events/Recent"
          style={{ fontWeight: 'bold' }}
          onClick={() => window.appInsights?.trackEvent({name: `Link Clicked - Current Events` })}
        >
          Current Events
        </NavLink>
      )
    }, {
      key: 'Planned Events Calendar',
      content: (
        <NavLink
          to="/Events/Upcoming"
          style={{ fontWeight: 'bold' }}
          onClick={() => window.appInsights?.trackEvent({name: `Link Clicked - Planned Events` })}
        >
          Planned Events
        </NavLink>
      )
    }
  ];

  const palette = generatePalette('#005cab');
  const { Title } = Typography;

  return (
    <>
      <Title level={4} style={{ color: '#595959', marginBottom: '0' }}>Events</Title>
      <Row gutter={[8, 8]} style={{ height: 'calc(100% - 3rem)' }}>
        {items.map((val) => (
          <Col span={12} style={{ height: '100%' }} key={val.key}>
            <AntButton
              block
              size='small'
              style={{ fontSize: '1.2rem', color: '#595959', backgroundColor: palette[0] }}
            >
              {val.content}
            </AntButton>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default EventBox;