import { useContext } from 'react';
import { withRouter } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	QlikUser as QlikUserAtom,
	AppState as AppStateAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { usePageTitle } from '@trinity-incyte/hooks';

declare var window: any;

// Called when my app has identified the user.
const Authenticated = (signInId) => {
    var validatedId = signInId.replace(/[,;=| ]+/g, "_");
    window.appInsights.setAuthenticatedUserContext(validatedId);
}

// This component will get rendered on every *change* to history
// i.e. not just a PUSH, but REPLACE, etc.
const WithLocation = ({ match, location: { pathname }, history }) => {
	const setAppState = useSetRecoilState(AppStateAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);

	const Config = useContext(ConfigContext);
	usePageTitle({
		defaultTitle: Config.App.title,
		title: pathname.replace(/\/\d{2,}$/g, '').replace(/^\//g, ''), //removes the profId from the title and the first path slash
	});
	localStorage.setItem('currentPage', pathname);

	// Azure analytics initialized
	let authUserToRecord = QlikUser.authenticatedUser;
	Authenticated(authUserToRecord);

	const appStatusUrl = Config.App.siteStatusUrl;
	fetch(appStatusUrl, {
		mode: 'cors' // Since this is cross-origin, need this to be set
	})
	.then(response => response.json())
	.then((response) => {		
		if (response.data) {
			const appStatus = response.data.reduce((acc, curr) => {
				if (curr.appName === Config.App.name) acc = curr;
				return acc;
			}, null);
			if (appStatus && appStatus.siteUp === false) {
				const isTestUser = QlikUser?.authenticatedUser?.includes('svc_bis_test');
				if (!((QlikUser.accessType == 'ADMIN') || (isTestUser))) { // ADMIN users never go into maintenance state
					setAppState({
						state: 'MAINTENANCE',
						error: 'MAINTENANCE',
						content: appStatus.siteDownMessage
					});
				}
			}
		}
	});

	return null;
};

export const LocationKeeper = withRouter(WithLocation);

export default LocationKeeper;
