import { FilterOutlined } from '@ant-design/icons';
import { ConfigContext } from '@trinity-incyte/context';
import { useQSListbox } from '@trinity-incyte/hooks';
import { Select } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { useParams } from 'react-router-dom';

declare const window: any;
declare var Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface QSFieldSelectionProps {
  config?: any;
  definition?: string[];
  title?: string;
  placeholder?: string;
  set_value?: Function;
  sort?: (a: any, b: any) => number;
  filter?: (a: any, b: any) => boolean;
  allowClear?: boolean;
  allowDisabledOptions?: boolean;
  multi?: boolean;
  defaultValue?: string;
  style?: object;
}

export function QSFieldSelection({
  config,
  definition,
  title,
  defaultValue,
  placeholder = 'Select one...',
  set_value = (_: any) => undefined,
  sort = ({ qText: opt1 }, { qText: opt2 }) =>
    opt1 > opt2 ? 1 : opt1 === opt2 ? 0 : -1, //Default to alphanumeric sort
  allowClear = false,
  filter = (val) => (true), // Default to show all
  allowDisabledOptions = false,
  multi = false,
  style
}: QSFieldSelectionProps) {
  const Config = useContext(ConfigContext);
  const [searchText, set_searchText] = useState(null);
  const { rows: listbox } = useQSListbox({
    config,
    definition,
    title,
    single: !multi,
    searchText
  });
  const [listboxOptions, set_listboxOptions] = useState(listbox
    .sort(sort)
    .map(({ qText, qElemNumber, qState }) => ({
      value: qElemNumber,
      label: qText,
      disabled: qState === Config.Qlik.qStateValues.EXCLUDED && !allowDisabledOptions,
      key: qText,
      title: qText,
    }))
    .filter((option) => { return option.disabled === false }));
  const [singleSelectionValue, set_singleSelectionValue] = useState(undefined);
  const [multiSelectionValue, set_multiSelectionValue] = useState([]);

  const singleSelection = useCallback(
    ({ value: newValue }) =>
      listbox
        .find(({ qElemNumber }) => qElemNumber === newValue)
        ?.select(),
    [listbox]
  );
  const multiSelection = useCallback(
    (newValue: { value: number; label: string }[]) => {
      
      const currentSelectedElems = listbox.reduce((acc, curr) => {
        if (curr.qState === Config.Qlik.qStateValues.SELECTED)
          acc.push({ label: curr.qText, value: curr.qElemNumber });
        return acc;
      }, []);

      if (currentSelectedElems.length > newValue.length) {
        
        // Deselect
        const changedCellElem = currentSelectedElems.filter(
          (curr) =>
            !newValue.find((item) => item.value === curr.value)
        )[0];

        if (!changedCellElem) {
          listbox[0].clear();
          return;
        }

        listbox
          .reduce((acc, curr) => {
            if (curr.qElemNumber === changedCellElem.value)
              acc = curr;
            return acc;
          }, null)
          ?.unselect();

      } else if (currentSelectedElems.length <= newValue.length) {

        // Find the new item
        const changedCellElem = newValue.filter(
          (item) =>
            !currentSelectedElems.find(
              (currItem) => currItem.value === item.value
            )
        )[0];

        // if there are no changed elements, clear the list
        if (!changedCellElem) {
          listbox[0].clear();
          return;
        }
        
        const appId = config.ids.appId;
        let newListBoxes = []; // array for qlik
        listbox.forEach((listBoxItem) => {
            if ((listBoxItem.qElemNumber === changedCellElem.value) || (listBoxItem.qState==Config.Qlik.qStateValues.SELECTED)) {
              newListBoxes.push({qText: listBoxItem.qText });
            }
        });
        Mosaic.Qlik.app[appId].field( definition[0] ).selectValues( newListBoxes );

        }

    },
    [listbox]
  );

  useEffect(() => {
    if (listbox?.length == 0) return;
    if (multi) {

      let multiSelections = [];
      listbox.forEach((listboxItem) => { 
        if (listboxItem.qState === Config.Qlik.qStateValues.SELECTED) {
          multiSelections.push({ label: listboxItem.qText, value: listboxItem.qElemNumber });
        }
      });
      
      set_multiSelectionValue(multiSelections); 
      set_singleSelectionValue(undefined);

    } else {
      let selectedValue = listbox.find((value) => value.qState === Config.Qlik.qStateValues.SELECTED);
      if (selectedValue == null && defaultValue) {
        selectedValue = listbox.find(({ qText }) => qText === defaultValue);
        selectedValue?.select();
      }
      set_singleSelectionValue({value: selectedValue?.qElemNumber, label: selectedValue?.qText});
      set_value(selectedValue?.qText);
    }

    set_listboxOptions(listbox
      .filter(filter)
      .sort(sort)
      .map(({ qText, qElemNumber, qState }) => ({
        value: qElemNumber,
        label: qText,
        disabled: qState === Config.Qlik.qStateValues.EXCLUDED && !allowDisabledOptions,
        key: qText,
        title: qText,
      }))
      .filter((option) => { return option.disabled === false }));
  }, [listbox]);
  
  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={(text) => {
        set_searchText(text);
      }}
      labelInValue
      mode={multi ? 'multiple' : undefined}
      maxTagCount={multi ? 10 : 4}
      showArrow
      style={style ?? (multi ? { minWidth: '100%', margin: '4px', width: '100%' } : {minWidth:'100px', margin: '4px', width: '100%'})}
      allowClear={allowClear}
      placeholder={placeholder}
      value={multi ? multiSelectionValue : singleSelectionValue}
      onClear={() => listbox[0].clear()}
      onDropdownVisibleChange={(open) => {
        if (open === false) {
          set_searchText(null);
        }
      }}
      onChange={multi ? multiSelection : singleSelection}
      onSelect={(e) => {
          window.appInsights?.trackEvent({name: `Button Clicked - QSField Selection Dropdown Clicked ${title}` })
      }
      }
      options={listboxOptions}
    />
  );

}
export default QSFieldSelection;